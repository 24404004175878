import FormSymbolImages from "./AddSymbolPrintList";

export default function toPDFMakeData(props: any) {
  const injectImages = (haveHeader: boolean) => {
    let symbolUsage = {};
    if (!haveHeader) {
      symbolUsage = {
        checked: FormSymbolImages.checked,
        unchecked: FormSymbolImages.unchecked,
        squarebox: FormSymbolImages.squarebox,
        checkmark: FormSymbolImages.checkmark,
      };
    }
    return symbolUsage;
  };

  return {
    pageSize: `A4`,
    defaultStyle: {
      font: `THSarabunNew`,
    },
    pageOrientation: `portrait`,
    content: [
      {
        preserveLeadingSpaces: true,
        alignment: `center`,
        margin: [0, 0, 0, 0],
        decorationStyle: ``,
        decorationColor: ``,
        fontSize: `20`,
        bold: `true`,
        color: ``,
        width: `auto`,
        text: `แบบฟอร์มลงทะเบียนผู้ป่วยใหม่`,
        decoration: ``,
        pageBreak: ``,
      },
      {
        pageBreak: ``,
        color: ``,
        decoration: ``,
        text: `New Patient Registration Form`,
        decorationStyle: ``,
        decorationColor: ``,
        width: `auto`,
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
        alignment: `center`,
        fontSize: 15,
      },
      {
        alignment: `left`,
        preserveLeadingSpaces: true,
        fontSize: 15,
        decoration: ``,
        width: `auto`,
        decorationStyle: ``,
        color: ``,
        margin: [0, 0, 0, 0],
        bold: false,
        text: ` `,
        decorationColor: ``,
        pageBreak: ``,
      },
      {
        pageBreak: ``,
        decorationStyle: ``,
        width: `auto`,
        preserveLeadingSpaces: true,
        fontSize: 15,
        bold: false,
        text: `เรียน ผู้รับบริการทุกท่าน`,
        margin: [0, 0, 0, 0],
        decoration: ``,
        color: ``,
        alignment: `left`,
        decorationColor: ``,
      },
      {
        columns: [
          {
            color: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            bold: false,
            alignment: `left`,
            decoration: ``,
            width: `auto`,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            pageBreak: ``,
            decorationColor: ``,
            text: `\t\tยินดีต้อนรับสู่`,
          },
          {
            text: ``,
            decoration: ``,
            width: 3,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            color: ``,
            pageBreak: ``,
            bold: false,
          },
          {
            pageBreak: ``,
            bold: false,
            fontSize: 15,
            decorationStyle: ``,
            alignment: `left`,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            text: props.items?.hospital,
            width: `auto`,
          },
          {
            color: ``,
            pageBreak: ``,
            alignment: `left`,
            bold: false,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: 3,
            decoration: ``,
            decorationColor: ``,
            text: ``,
          },
          {
            fontSize: 15,
            decorationStyle: ``,
            bold: false,
            decorationColor: ``,
            color: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            alignment: `left`,
            text: `ในฐานะที่ท่านเป็นผู้ป่วยใหม่ ทางโรงพยาบาลขอรบกวนเวลาของท่านใน`,
            width: `auto`,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        alignment: `left`,
        fontSize: 15,
        decorationStyle: ``,
        text: `การกรอกข้อมูลในแบบฟอร์มฉบับนี้ เพื่อให้ทางโรงพยาบาลได้ให้บริการแก่ท่านได้อย่างมีประสิทธิภาพ หากเป็นไปได้ขอให้ท่านกรอก`,
        pageBreak: ``,
        margin: [0, 0, 0, 0],
        color: ``,
        width: `auto`,
        decoration: ``,
        bold: false,
        preserveLeadingSpaces: true,
        decorationColor: ``,
      },
      {
        pageBreak: ``,
        decoration: ``,
        color: ``,
        width: `auto`,
        decorationColor: ``,
        text: `ข้อมูลให้ครบทุกข้อตามที่กำหนดไว้ หรืออย่างน้อยที่สุดขอให้ท่านกรอกข้อมูลที่จำเป็นต่อการรักษาพยาบาล ตามข้อที่มีเครื่องหมาย (*)`,
        alignment: `left`,
        preserveLeadingSpaces: true,
        fontSize: 15,
        margin: [0, 0, 0, 0],
        bold: false,
        decorationStyle: ``,
      },
      {
        columns: [
          {
            text: `กำกับไว้ และทำเครื่องหมาย`,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 3,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            image: "checkmark",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 3,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: `ในช่อง`,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 3,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 3,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: `ให้ครบถ้วนชัดเจน ทั้งนี้เพื่อให้การบริการรักษาพยาบาลท่านเป็นไปอย่างดีที่สุด`,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        fontSize: 15,
        bold: false,
        preserveLeadingSpaces: true,
        decoration: ``,
        decorationStyle: ``,
        pageBreak: ``,
        alignment: `left`,
        text: ` `,
        decorationColor: ``,
        width: `auto`,
        margin: [0, 0, 0, 0],
        color: ``,
      },
      {
        preserveLeadingSpaces: true,
        text: `ประวัติส่วนตัว`,
        bold: `true`,
        fontSize: `16`,
        decorationStyle: ``,
        pageBreak: ``,
        color: ``,
        decorationColor: ``,
        width: `auto`,
        decoration: ``,
        alignment: `left`,
        margin: [0, 0, 0, 0],
      },
      {
        text: `เพื่อประโยชน์ต่อตัวท่าน โปรดแจ้งประวัติการแพ้ (ยา/อาหาร/สารเคมี/สารทึบรังสี) *`,
        width: `auto`,
        decorationStyle: ``,
        decoration: ``,
        preserveLeadingSpaces: true,
        alignment: `left`,
        pageBreak: ``,
        bold: false,
        margin: [0, 0, 0, 0],
        color: ``,
        fontSize: 15,
        decorationColor: ``,
      },
      {
        columns: [
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            preserveLeadingSpaces: true,
            bold: false,
            width: 5,
            fontSize: 15,
            text: ``,
            color: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            decorationColor: ``,
            decorationStyle: ``,
            decoration: ``,
          },
          {
            pageBreak: ``,
            decorationColor: ``,
            fontSize: 15,
            decoration: ``,
            alignment: `left`,
            text: `แพ้ (ระบุ) .........................................................................................................................................................................................`,
            bold: false,
            decorationStyle: ``,
            color: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        columns: [
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            decorationStyle: ``,
            text: ``,
            fontSize: 15,
            pageBreak: ``,
            color: ``,
            alignment: `left`,
            width: 5,
            decoration: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
          },
          {
            preserveLeadingSpaces: true,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            bold: false,
            decorationStyle: ``,
            decorationColor: ``,
            text: `ไม่แพ้`,
            pageBreak: ``,
            width: `auto`,
            fontSize: 15,
            decoration: ``,
            color: ``,
          },
          {
            color: ``,
            alignment: `left`,
            bold: false,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            width: 10,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            text: ``,
            decoration: ``,
            decorationColor: ``,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            decorationColor: ``,
            decoration: ``,
            bold: false,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            color: ``,
            text: ``,
            width: 5,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
          },
          {
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            decorationColor: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            text: `ไม่ทราบ`,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            text: `เลขที่บัตรประจำตัวประชาชน *`,
            fontSize: 15,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            color: ``,
            width: `auto`,
            decoration: ``,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
          },
          {
            color: ``,
            width: 5,
            bold: false,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            decoration: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            text: ``,
            fontSize: 15,
            alignment: `left`,
          },
          { image: "squarebox", margin: [0, 0, 0, 0], width: 15 },
          {
            decoration: ``,
            bold: false,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            width: 2,
            pageBreak: ``,
            alignment: `left`,
            color: ``,
            decorationStyle: ``,
            text: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
          },
          { image: "squarebox", margin: [0, 0, 0, 0], width: 15 },
          {
            decoration: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            bold: false,
            decorationStyle: ``,
            alignment: `left`,
            text: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            color: ``,
            width: 2,
            pageBreak: ``,
          },
          { image: "squarebox", margin: [0, 0, 0, 0], width: 15 },
          {
            pageBreak: ``,
            color: ``,
            bold: false,
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            width: 2,
            alignment: `left`,
            text: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
          },
          { image: "squarebox", margin: [0, 0, 0, 0], width: 15 },
          {
            decorationColor: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            decoration: ``,
            text: ``,
            width: 2,
            preserveLeadingSpaces: true,
            fontSize: 15,
            alignment: `left`,
            color: ``,
            bold: false,
          },
          { image: "squarebox", margin: [0, 0, 0, 0], width: 15 },
          {
            bold: false,
            decorationColor: ``,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
            decoration: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            width: 2,
            alignment: `left`,
            text: ``,
            margin: [0, 0, 0, 0],
          },
          { image: "squarebox", margin: [0, 0, 0, 0], width: 15 },
          {
            decoration: ``,
            decorationColor: ``,
            text: ``,
            preserveLeadingSpaces: true,
            bold: false,
            alignment: `left`,
            width: 2,
            decorationStyle: ``,
            fontSize: 15,
            color: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
          },
          { image: "squarebox", margin: [0, 0, 0, 0], width: 15 },
          {
            bold: false,
            alignment: `left`,
            decorationStyle: ``,
            width: 2,
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            color: ``,
            text: ``,
          },
          { image: "squarebox", margin: [0, 0, 0, 0], width: 15 },
          {
            decoration: ``,
            decorationStyle: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            text: ``,
            width: 2,
            decorationColor: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            color: ``,
            pageBreak: ``,
          },
          { image: "squarebox", margin: [0, 0, 0, 0], width: 15 },
          {
            fontSize: 15,
            margin: [0, 0, 0, 0],
            width: 2,
            pageBreak: ``,
            alignment: `left`,
            decorationColor: ``,
            bold: false,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decoration: ``,
            text: ``,
          },
          { image: "squarebox", margin: [0, 0, 0, 0], width: 15 },
          {
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
            width: 2,
            color: ``,
            text: ``,
            decoration: ``,
            fontSize: 15,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            alignment: `left`,
          },
          { image: "squarebox", margin: [0, 0, 0, 0], width: 15 },
          {
            color: ``,
            text: ``,
            fontSize: 15,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            bold: false,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            width: 2,
            decoration: ``,
          },
          { image: "squarebox", margin: [0, 0, 0, 0], width: 15 },
          {
            text: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            bold: false,
            fontSize: 15,
            width: 2,
            alignment: `left`,
            decorationColor: ``,
            color: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
          },
          { image: "squarebox", margin: [0, 0, 0, 0], width: 15 },
          // Fill Citizen No in the Box
          {
            text: props.items?.citizen_split[0],
            fontSize: 15,
            absolutePosition: { x: 170, y: 380 },
          },
          {
            text: props.items?.citizen_split[1],
            fontSize: 15,
            absolutePosition: { x: 187, y: 380 },
          },
          {
            text: props.items?.citizen_split[2],
            fontSize: 15,
            absolutePosition: { x: 204, y: 380 },
          },
          {
            text: props.items?.citizen_split[3],
            fontSize: 15,
            absolutePosition: { x: 221, y: 380 },
          },
          {
            text: props.items?.citizen_split[4],
            fontSize: 15,
            absolutePosition: { x: 238, y: 380 },
          },
          {
            text: props.items?.citizen_split[5],
            fontSize: 15,
            absolutePosition: { x: 255, y: 380 },
          },
          {
            text: props.items?.citizen_split[6],
            fontSize: 15,
            absolutePosition: { x: 272, y: 380 },
          },
          {
            text: props.items?.citizen_split[7],
            fontSize: 15,
            absolutePosition: { x: 289, y: 380 },
          },
          {
            text: props.items?.citizen_split[8],
            fontSize: 15,
            absolutePosition: { x: 306, y: 380 },
          },
          {
            text: props.items?.citizen_split[9],
            fontSize: 15,
            absolutePosition: { x: 323, y: 380 },
          },
          {
            text: props.items?.citizen_split[10],
            fontSize: 15,
            absolutePosition: { x: 340, y: 380 },
          },
          {
            text: props.items?.citizen_split[11],
            fontSize: 15,
            absolutePosition: { x: 357, y: 380 },
          },
          {
            text: props.items?.citizen_split[12],
            fontSize: 15,
            absolutePosition: { x: 374, y: 380 },
          },
        ],
      },
      {
        columns: [
          {
            text: `เลขที่ ใบต่างด้าว/หนังสือเดินทาง`,
            decorationColor: ``,
            width: `auto`,
            pageBreak: ``,
            decoration: ``,
            bold: false,
            decorationStyle: ``,
            color: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            alignment: `left`,
          },
          {
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            text:
              props.items?.citizen_no.length > 0 ||
              props.items?.citizen_no !== "Non citizen identity"
                ? ` .............................................................................................................................................................`
                : props.items?.alien_no.length > 0 ||
                  props.items?.alien_no !== "Non citizen identity"
                ? props.items?.alien_no
                : props.items?.citizen_passport.length > 0 ||
                  props.items?.citizen_passport !== "Non citizen identity"
                ? props.items?.citizen_passport
                : ` .............................................................................................................................................................`,
            width: `auto`,
            bold: false,
            color: ``,
            alignment: `left`,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            alignment: `left`,
            width: `auto`,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            decoration: ``,
            decorationColor: ``,
            bold: false,
            decorationStyle: ``,
            text: `วันหมดอายุ`,
            preserveLeadingSpaces: true,
          },
          {
            text: ` ...............................................................................................................................................................................................`,
            fontSize: 15,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            decoration: ``,
            color: ``,
            alignment: `left`,
            width: `auto`,
            bold: false,
          },
        ],
      },
      {
        pageBreak: ``,
        margin: [0, 0, 0, 0],
        alignment: `left`,
        text: ` `,
        color: ``,
        decoration: ``,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        bold: false,
        width: `auto`,
        decorationColor: ``,
        fontSize: 15,
      },
      {
        columns: [
          {
            decoration: ``,
            alignment: `left`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            text: `คำนำหน้าชื่อ *`,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            bold: false,
          },
          {
            color: ``,
            alignment: `left`,
            decoration: ``,
            width: 5,
            decorationColor: ``,
            text: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            bold: false,
            decorationStyle: ``,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            decoration: ``,
            margin: [0, 0, 0, 0],
            text: ``,
            decorationStyle: ``,
            alignment: `left`,
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            fontSize: 15,
            pageBreak: ``,
            width: 3,
            bold: false,
          },
          {
            decorationStyle: ``,
            text: `นาย (Mr.)`,
            fontSize: 15,
            decoration: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decorationColor: ``,
            pageBreak: ``,
            alignment: `left`,
            width: `auto`,
          },
          {
            decorationStyle: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            fontSize: 15,
            width: 3,
            decorationColor: ``,
            color: ``,
            alignment: `left`,
            bold: false,
            text: ``,
            margin: [0, 0, 0, 0],
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            bold: false,
            text: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            color: ``,
            width: 3,
            pageBreak: ``,
            alignment: `left`,
            fontSize: 15,
          },
          {
            decorationColor: ``,
            alignment: `left`,
            color: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            text: `นาง (Mrs.)`,
            decorationStyle: ``,
            decoration: ``,
            bold: false,
            pageBreak: ``,
          },
          {
            decorationColor: ``,
            color: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            alignment: `left`,
            decorationStyle: ``,
            pageBreak: ``,
            bold: false,
            decoration: ``,
            margin: [0, 0, 0, 0],
            width: 3,
            text: ``,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            width: 3,
            margin: [0, 0, 0, 0],
            text: ``,
            decorationStyle: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
            pageBreak: ``,
            decoration: ``,
            alignment: `left`,
            decorationColor: ``,
            color: ``,
          },
          {
            bold: false,
            alignment: `left`,
            decoration: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            text: `นางสาว (Miss)`,
            color: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            width: `auto`,
            decorationStyle: ``,
            pageBreak: ``,
          },
          {
            decorationColor: ``,
            decorationStyle: ``,
            fontSize: 15,
            decoration: ``,
            width: 3,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            text: ``,
            bold: false,
            color: ``,
            pageBreak: ``,
            alignment: `left`,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
            bold: false,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            pageBreak: ``,
            text: ``,
            width: 3,
            decorationColor: ``,
            alignment: `left`,
          },
          {
            pageBreak: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationStyle: ``,
            bold: false,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            text: `ด.ช. (Master)`,
            alignment: `left`,
          },
          {
            preserveLeadingSpaces: true,
            alignment: `left`,
            bold: false,
            text: ``,
            decorationStyle: ``,
            pageBreak: ``,
            decoration: ``,
            width: 3,
            color: ``,
            decorationColor: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            decoration: ``,
            decorationColor: ``,
            text: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: 3,
            bold: false,
            decorationStyle: ``,
            alignment: `left`,
          },
          {
            decorationStyle: ``,
            color: ``,
            text: `ด.ญ. (Miss)`,
            width: `auto`,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            bold: false,
            fontSize: 15,
          },
          {
            decorationStyle: ``,
            decoration: ``,
            bold: false,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            text: ``,
            fontSize: 15,
            color: ``,
            width: 3,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            decoration: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            text: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            alignment: `left`,
            width: 3,
          },
          {
            decorationColor: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            text: `อื่นๆ (Other) ........................`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
            width: `auto`,
            decorationStyle: ``,
            color: ``,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            bold: false,
            text: `ชื่อภาษาไทย * ชื่อต้น`,
            fontSize: 15,
            decoration: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            color: ``,
            width: `auto`,
            decorationStyle: ``,
            alignment: `left`,
          },
          {
            color: ``,
            decoration: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            bold: false,
            text: ``,
            width: 15,
            decorationStyle: ``,
          },
          {
            stack: [
              {
                alignment: `left`,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                text: props.items?.first_name_th?.length > 0 ? props.items?.first_name_th : " ",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
              {
                alignment: `left`,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                text: "....................................",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
            ],
            width: `auto`,
          },
          {
            decorationColor: ``,
            text: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            bold: false,
            pageBreak: ``,
            width: 15,
            decoration: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            fontSize: 15,
          },
          {
            decoration: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            text: `ชื่อกลาง (ถ้ามี)`,
            decorationStyle: ``,
            color: ``,
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
            width: `auto`,
            pageBreak: ``,
          },
          {
            bold: false,
            alignment: `left`,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            text: ``,
            decorationStyle: ``,
            width: 15,
            color: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            pageBreak: ``,
            decoration: ``,
          },
          {
            stack: [
              {
                alignment: `left`,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                text: props.items?.middle_name_th?.length > 0 ? props.items?.middle_name_th : " ",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
              {
                alignment: `left`,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                text: "....................................",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
            ],
            width: `auto`,
          },
          {
            width: 15,
            color: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            fontSize: 15,
            bold: false,
            decorationStyle: ``,
            text: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            decorationColor: ``,
          },
          {
            width: `auto`,
            decorationColor: ``,
            decoration: ``,
            alignment: `left`,
            pageBreak: ``,
            bold: false,
            preserveLeadingSpaces: true,
            text: `นามสกุล`,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            color: ``,
          },
          {
            decorationStyle: ``,
            bold: false,
            width: 15,
            decoration: ``,
            fontSize: 15,
            color: ``,
            decorationColor: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            text: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
          // เพิ่ม First, Mid, Last Name ภาษาอังกฤษ
          {
            stack: [
              {
                alignment: `left`,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                text: props.items?.last_name_th?.length > 0 ? props.items?.last_name_th : " ",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
              {
                alignment: `left`,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                text: "....................................",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
            ],
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            text: `ชื่อภาษาอังกฤษ First Name`,
            bold: false,
            color: ``,
            decoration: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decorationStyle: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
          },
          {
            decorationStyle: ``,
            decorationColor: ``,
            text: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            width: 15,
            decoration: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            alignment: `left`,
            bold: false,
            pageBreak: ``,
          },
          {
            stack: [
              {
                alignment: `left`,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                text: props.items?.first_name_en?.length > 0 ? props.items?.first_name_en : " ",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
              {
                alignment: `left`,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                text: "..............................",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
            ],
            width: `auto`,
          },
          {
            alignment: `left`,
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            color: ``,
            preserveLeadingSpaces: true,
            width: 15,
            margin: [0, 0, 0, 0],
            text: ``,
            bold: false,
          },
          {
            decorationColor: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            text: `Middle Name`,
            decoration: ``,
            pageBreak: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: `auto`,
            bold: false,
            alignment: `left`,
          },
          {
            color: ``,
            decoration: ``,
            decorationStyle: ``,
            fontSize: 15,
            width: 15,
            text: ``,
            decorationColor: ``,
            alignment: `left`,
            bold: false,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
          },
          {
            stack: [
              {
                alignment: `left`,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                text: props.items?.middle_name_en?.length > 0 ? props.items?.middle_name_en : " ",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
              {
                alignment: `left`,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                text: "..............................",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
            ],
            width: `auto`,
          },
          {
            decoration: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            text: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            bold: false,
            color: ``,
            decorationColor: ``,
            width: 15,
            fontSize: 15,
          },
          {
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
            width: `auto`,
            color: ``,
            decorationColor: ``,
            alignment: `left`,
            text: `Last Name`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            decoration: ``,
            fontSize: 15,
          },
          {
            decorationColor: ``,
            bold: false,
            color: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            alignment: `left`,
            decoration: ``,
            preserveLeadingSpaces: true,
            width: 15,
            pageBreak: ``,
            text: ``,
            decorationStyle: ``,
          },
          {
            stack: [
              {
                alignment: `left`,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                text: props.items?.last_name_en?.length > 0 ? props.items?.last_name_en : " ",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
              {
                alignment: `left`,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                text: "..............................",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
            ],
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            text: `เพศ *`,
            color: ``,
            decorationColor: ``,
            width: `auto`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            bold: false,
            pageBreak: ``,
            decoration: ``,
          },
          {
            bold: false,
            width: 5,
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            text: ``,
            alignment: `left`,
          },
          {
            image: props.items?.gender === "M" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
          },
          {
            decorationStyle: ``,
            decoration: ``,
            text: ``,
            width: 5,
            pageBreak: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            bold: false,
            fontSize: 15,
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
          },
          {
            alignment: `left`,
            fontSize: 15,
            decoration: ``,
            bold: false,
            width: `auto`,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            text: `ชาย`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
          },
          {
            pageBreak: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            text: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            width: 5,
            decorationColor: ``,
            decoration: ``,
          },
          {
            image: props.items?.gender === "F" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
          },
          {
            decorationColor: ``,
            width: 5,
            alignment: `left`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            text: ``,
            decoration: ``,
            pageBreak: ``,
            bold: false,
            color: ``,
          },
          {
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            color: ``,
            decorationStyle: ``,
            width: `auto`,
            alignment: `left`,
            decorationColor: ``,
            text: `หญิง`,
            bold: false,
            fontSize: 15,
          },
          {
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            decorationStyle: ``,
            fontSize: 15,
            width: 10,
            decoration: ``,
            pageBreak: ``,
            color: ``,
            text: ``,
            preserveLeadingSpaces: true,
            bold: false,
            alignment: `left`,
          },
          {
            color: ``,
            text: `วัน เดือน พ.ศ. เกิด *`,
            alignment: `left`,
            decoration: ``,
            bold: false,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            fontSize: 15,
            width: `auto`,
          },
          {
            alignment: `left`,
            decorationColor: ``,
            decorationStyle: ``,
            pageBreak: ``,
            color: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            text: ``,
            preserveLeadingSpaces: true,
            width: 5,
            decoration: ``,
          },
          {
            stack: [
              {
                alignment: `left`,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                text:
                  props.items?.birthdate.length > 0
                    ? props.items?.birthdate
                    : "        /          /",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
              {
                alignment: `left`,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                text: ".........................................",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
            ],
            width: `auto`,
          },
          {
            decoration: ``,
            pageBreak: ``,
            bold: false,
            width: 5,
            decorationStyle: ``,
            fontSize: 15,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            text: ``,
          },
          {
            fontSize: 15,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            bold: false,
            color: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            text: `อายุ`,
            decorationStyle: ``,
            alignment: `left`,
            decoration: ``,
            width: `auto`,
          },
          {
            decorationColor: ``,
            decoration: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            color: ``,
            bold: false,
            width: 5,
            text: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationStyle: ``,
          },
          {
            stack: [
              {
                alignment: `left`,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                text: props.items?.age ? props.items?.age : " ",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
              {
                alignment: `left`,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                text: ".........",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
            ],
            width: `auto`,
          },
          {
            decorationStyle: ``,
            fontSize: 15,
            bold: false,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            color: ``,
            alignment: `left`,
            text: ``,
            width: 5,
            decoration: ``,
            pageBreak: ``,
            decorationColor: ``,
          },
          {
            alignment: `left`,
            pageBreak: ``,
            color: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decoration: ``,
            fontSize: 15,
            text: `ปี`,
            width: `auto`,
            decorationStyle: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
          },
          {
            decoration: ``,
            decorationColor: ``,
            text: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            pageBreak: ``,
            fontSize: 15,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            width: 5,
            color: ``,
            bold: false,
          },
          {
            width: `auto`,
            fontSize: 15,
            decorationStyle: ``,
            bold: false,
            decoration: ``,
            color: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            text: `จังหวัดที่เกิด ...........................................`,
            decorationColor: ``,
            alignment: `left`,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            alignment: `left`,
            fontSize: 15,
            width: `auto`,
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            text: `ประเทศ .................................................`,
            decorationStyle: ``,
            bold: false,
            decoration: ``,
          },
          {
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            pageBreak: ``,
            width: 5,
            text: ``,
            fontSize: 15,
            decoration: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationStyle: ``,
            bold: false,
          },
          {
            bold: false,
            pageBreak: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            text: `อาชีพ .................................................`,
            decoration: ``,
            width: `auto`,
            decorationColor: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            fontSize: 15,
          },
          {
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
            fontSize: 15,
            decorationStyle: ``,
            text: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            decorationColor: ``,
            width: 5,
            alignment: `left`,
          },
          {
            alignment: `left`,
            color: ``,
            decorationColor: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            fontSize: 15,
            bold: false,
            text: `ตำแหน่ง/หน้าที่ ..........................................................`,
            width: `auto`,
            preserveLeadingSpaces: true,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            width: `auto`,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            decorationStyle: ``,
            pageBreak: ``,
            color: ``,
            decoration: ``,
            bold: false,
            fontSize: 15,
            decorationColor: ``,
            text: `หมู่เลือด`,
          },
          {
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            fontSize: 15,
            text: ``,
            decorationColor: ``,
            decorationStyle: ``,
            decoration: ``,
            alignment: `left`,
            color: ``,
            bold: false,
            preserveLeadingSpaces: true,
            width: 10,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            width: 5,
            preserveLeadingSpaces: true,
            decoration: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            alignment: `left`,
            color: ``,
            pageBreak: ``,
            fontSize: 15,
            bold: false,
            decorationStyle: ``,
            text: ``,
          },
          {
            text: `เอ`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            alignment: `left`,
            bold: false,
            pageBreak: ``,
            width: `auto`,
            margin: [0, 0, 0, 0],
            color: ``,
            decorationStyle: ``,
            decoration: ``,
          },
          {
            decoration: ``,
            decorationColor: ``,
            fontSize: 15,
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            color: ``,
            text: ``,
            margin: [0, 0, 0, 0],
            width: 5,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            width: 5,
            decorationStyle: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            decoration: ``,
            text: ``,
            bold: false,
            pageBreak: ``,
            color: ``,
          },
          {
            decorationColor: ``,
            text: `บี`,
            fontSize: 15,
            color: ``,
            decorationStyle: ``,
            pageBreak: ``,
            alignment: `left`,
            decoration: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            width: `auto`,
          },
          {
            fontSize: 15,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            bold: false,
            text: ``,
            decoration: ``,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            width: 5,
            color: ``,
            pageBreak: ``,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
            color: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            bold: false,
            width: 5,
            fontSize: 15,
            alignment: `left`,
            text: ``,
          },
          {
            text: `เอบี`,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            pageBreak: ``,
            fontSize: 15,
            bold: false,
            alignment: `left`,
            color: ``,
            decoration: ``,
            width: `auto`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
          },
          {
            decorationStyle: ``,
            text: ``,
            pageBreak: ``,
            decoration: ``,
            color: ``,
            fontSize: 15,
            decorationColor: ``,
            alignment: `left`,
            width: 5,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            bold: false,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            decorationStyle: ``,
            width: 5,
            fontSize: 15,
            bold: false,
            text: ``,
            alignment: `left`,
          },
          {
            text: `โอ`,
            alignment: `left`,
            decorationStyle: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
            width: `auto`,
            decorationColor: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            pageBreak: ``,
          },
          {
            preserveLeadingSpaces: true,
            color: ``,
            bold: false,
            decorationStyle: ``,
            width: 5,
            decorationColor: ``,
            text: ``,
            pageBreak: ``,
            decoration: ``,
            fontSize: 15,
            alignment: `left`,
            margin: [0, 0, 0, 0],
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            pageBreak: ``,
            bold: false,
            width: 5,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decorationColor: ``,
            alignment: `left`,
            decoration: ``,
            text: ``,
            fontSize: 15,
          },
          {
            color: ``,
            decorationStyle: ``,
            decoration: ``,
            pageBreak: ``,
            text: `ไม่ทราบ`,
            fontSize: 15,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            bold: false,
            decorationColor: ``,
            width: `auto`,
          },
          {
            bold: false,
            preserveLeadingSpaces: true,
            color: ``,
            fontSize: 15,
            decorationStyle: ``,
            decorationColor: ``,
            decoration: ``,
            width: 5,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            alignment: `left`,
            text: ``,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            decorationColor: ``,
            bold: false,
            decoration: ``,
            alignment: `left`,
            text: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            fontSize: 15,
            color: ``,
            width: 5,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
          },
          {
            pageBreak: ``,
            bold: false,
            preserveLeadingSpaces: true,
            width: `auto`,
            text: `ไม่เคยตรวจหมู่เลือด`,
            fontSize: 15,
            decorationStyle: ``,
            color: ``,
            decoration: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            color: ``,
            decorationStyle: ``,
            pageBreak: ``,
            text: `สัญชาติ *`,
            decoration: ``,
            width: `auto`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
          },
          {
            alignment: `left`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            text: ``,
            color: ``,
            width: 5,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            bold: false,
            decoration: ``,
            decorationColor: ``,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            color: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decoration: ``,
            decorationStyle: ``,
            width: 5,
            bold: false,
            text: ``,
            decorationColor: ``,
          },
          {
            color: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decoration: ``,
            text: `ไทย`,
            decorationColor: ``,
            decorationStyle: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            width: `auto`,
            bold: false,
            pageBreak: ``,
          },
          {
            width: 5,
            text: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            bold: false,
            decoration: ``,
            decorationColor: ``,
            fontSize: 15,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
            alignment: `left`,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            bold: false,
            fontSize: 15,
            alignment: `left`,
            decoration: ``,
            preserveLeadingSpaces: true,
            width: 5,
            color: ``,
            decorationColor: ``,
            pageBreak: ``,
            text: ``,
          },
          {
            color: ``,
            bold: false,
            decorationStyle: ``,
            alignment: `left`,
            width: `auto`,
            text: `พม่า`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            pageBreak: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decoration: ``,
          },
          {
            decorationColor: ``,
            decoration: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
            decorationStyle: ``,
            pageBreak: ``,
            width: 5,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            color: ``,
            text: ``,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            preserveLeadingSpaces: true,
            color: ``,
            fontSize: 15,
            decorationColor: ``,
            alignment: `left`,
            decoration: ``,
            margin: [0, 0, 0, 0],
            width: 5,
            text: ``,
            decorationStyle: ``,
            pageBreak: ``,
            bold: false,
          },
          {
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decoration: ``,
            width: `auto`,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            color: ``,
            text: `กัมพูชา`,
            bold: false,
            decorationStyle: ``,
          },
          {
            alignment: `left`,
            decorationColor: ``,
            decorationStyle: ``,
            bold: false,
            pageBreak: ``,
            fontSize: 15,
            width: 5,
            text: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            decorationColor: ``,
            decoration: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            color: ``,
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            text: ``,
            fontSize: 15,
            width: 5,
          },
          {
            fontSize: 15,
            bold: false,
            alignment: `left`,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            width: `auto`,
            decoration: ``,
            text: `จีน`,
            color: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
          },
          {
            decorationColor: ``,
            bold: false,
            decoration: ``,
            pageBreak: ``,
            text: ``,
            decorationStyle: ``,
            alignment: `left`,
            width: 5,
            fontSize: 15,
            preserveLeadingSpaces: true,
            color: ``,
            margin: [0, 0, 0, 0],
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            color: ``,
            text: ``,
            alignment: `left`,
            width: 5,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decoration: ``,
          },
          {
            decoration: ``,
            text: `อื่นๆ ระบุ ...........................................................................................................`,
            alignment: `left`,
            color: ``,
            width: `auto`,
            bold: false,
            pageBreak: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            decorationColor: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            pageBreak: ``,
            width: `auto`,
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            text: `เชื้อชาติ *`,
            fontSize: 15,
          },
          {
            bold: false,
            margin: [0, 0, 0, 0],
            decoration: ``,
            width: 5,
            color: ``,
            text: ``,
            fontSize: 15,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            pageBreak: ``,
            alignment: `left`,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            decorationColor: ``,
            color: ``,
            text: ``,
            decoration: ``,
            bold: false,
            fontSize: 15,
            alignment: `left`,
            width: 5,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            pageBreak: ``,
          },
          {
            decorationColor: ``,
            decoration: ``,
            text: `ไทย`,
            color: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            bold: false,
            width: `auto`,
            alignment: `left`,
            decorationStyle: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
          },
          {
            bold: false,
            margin: [0, 0, 0, 0],
            width: 5,
            color: ``,
            fontSize: 15,
            alignment: `left`,
            decorationColor: ``,
            pageBreak: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            text: ``,
            decorationStyle: ``,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            bold: false,
            width: 5,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
            alignment: `left`,
            text: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            pageBreak: ``,
            fontSize: 15,
          },
          {
            text: `พม่า`,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            width: `auto`,
            decoration: ``,
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            preserveLeadingSpaces: true,
            bold: false,
            pageBreak: ``,
            decorationColor: ``,
          },
          {
            width: 5,
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            text: ``,
            decoration: ``,
            pageBreak: ``,
            alignment: `left`,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            width: 5,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
            alignment: `left`,
            bold: false,
            color: ``,
            pageBreak: ``,
            text: ``,
            margin: [0, 0, 0, 0],
          },
          {
            decoration: ``,
            text: `กัมพูชา`,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
            pageBreak: ``,
            color: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            width: `auto`,
          },
          {
            text: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            width: 5,
            decorationStyle: ``,
            decorationColor: ``,
            bold: false,
            alignment: `left`,
            fontSize: 15,
            pageBreak: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            color: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            bold: false,
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            width: 5,
            preserveLeadingSpaces: true,
            fontSize: 15,
            text: ``,
            decorationStyle: ``,
          },
          {
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            text: `จีน`,
            alignment: `left`,
            decoration: ``,
            margin: [0, 0, 0, 0],
            width: `auto`,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            decorationStyle: ``,
          },
          {
            color: ``,
            decorationStyle: ``,
            decorationColor: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            width: 5,
            bold: false,
            preserveLeadingSpaces: true,
            text: ``,
            pageBreak: ``,
            alignment: `left`,
            fontSize: 15,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            text: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            fontSize: 15,
            decorationColor: ``,
            decorationStyle: ``,
            width: 5,
            alignment: `left`,
            decoration: ``,
            pageBreak: ``,
            color: ``,
            preserveLeadingSpaces: true,
          },
          {
            text: `อื่นๆ ระบุ ...........................................................................................................`,
            alignment: `left`,
            color: ``,
            decoration: ``,
            fontSize: 15,
            width: `auto`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            decorationStyle: ``,
            bold: false,
            text: `ศาสนา`,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            width: `auto`,
            color: ``,
            alignment: `left`,
          },
          {
            pageBreak: ``,
            text: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            fontSize: `16`,
            decoration: ``,
            bold: false,
            width: 15,
            alignment: `left`,
            color: ``,
            preserveLeadingSpaces: true,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            bold: false,
            text: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decorationColor: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            width: 5,
            alignment: `left`,
            decoration: ``,
            pageBreak: ``,
            decorationStyle: ``,
          },
          {
            color: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            decoration: ``,
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
            text: `พุทธ`,
            preserveLeadingSpaces: true,
            width: `auto`,
            bold: false,
            decorationColor: ``,
          },
          {
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            width: 5,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            text: ``,
            fontSize: 15,
            bold: false,
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            decoration: ``,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            fontSize: 15,
            pageBreak: ``,
            width: 5,
            preserveLeadingSpaces: true,
            alignment: `left`,
            text: ``,
            bold: false,
            color: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
          },
          {
            decoration: ``,
            width: `auto`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
            color: ``,
            fontSize: 15,
            text: `คริสต์`,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
          },
          {
            alignment: `left`,
            color: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decorationColor: ``,
            fontSize: 15,
            bold: false,
            decoration: ``,
            margin: [0, 0, 0, 0],
            width: 5,
            text: ``,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            margin: [0, 0, 0, 0],
            bold: false,
            color: ``,
            decorationColor: ``,
            text: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            alignment: `left`,
            decoration: ``,
            pageBreak: ``,
            fontSize: 15,
            width: 5,
          },
          {
            bold: false,
            decoration: ``,
            width: `auto`,
            text: `อิสลาม`,
            color: ``,
            pageBreak: ``,
            decorationStyle: ``,
            decorationColor: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            alignment: `left`,
          },
          {
            margin: [0, 0, 0, 0],
            decoration: ``,
            fontSize: 15,
            pageBreak: ``,
            color: ``,
            decorationStyle: ``,
            width: 5,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            alignment: `left`,
            text: ``,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            decorationColor: ``,
            text: ``,
            pageBreak: ``,
            decorationStyle: ``,
            alignment: `left`,
            decoration: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            bold: false,
            width: 5,
            preserveLeadingSpaces: true,
          },
          {
            decorationColor: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationStyle: ``,
            text: `ไม่นับถือศาสนา`,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            decoration: ``,
            color: ``,
            fontSize: 15,
            width: `auto`,
          },
          {
            fontSize: 15,
            width: 5,
            decoration: ``,
            text: ``,
            decorationColor: ``,
            color: ``,
            decorationStyle: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            color: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            bold: false,
            alignment: `left`,
            text: ``,
            decorationColor: ``,
            decoration: ``,
            width: 5,
          },
          {
            decorationStyle: ``,
            bold: false,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            width: `auto`,
            pageBreak: ``,
            fontSize: 15,
            color: ``,
            text: `อื่นๆ ระบุ ....................................................................................`,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            alignment: `left`,
            color: ``,
            text: `ความเชื่อ`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decoration: ``,
            width: `auto`,
          },
          {
            alignment: `left`,
            bold: false,
            pageBreak: ``,
            width: 7,
            decoration: ``,
            decorationColor: ``,
            fontSize: 15,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            text: ``,
            preserveLeadingSpaces: true,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            preserveLeadingSpaces: true,
            pageBreak: ``,
            alignment: `left`,
            decoration: ``,
            color: ``,
            text: ``,
            fontSize: 15,
            bold: false,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            decorationStyle: ``,
            width: 5,
          },
          {
            text: `ไม่มี`,
            width: `auto`,
            decorationColor: ``,
            bold: false,
            alignment: `left`,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
          },
          {
            alignment: `left`,
            decorationStyle: ``,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            color: ``,
            preserveLeadingSpaces: true,
            bold: false,
            width: 5,
            text: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            text: ``,
            bold: false,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationColor: ``,
            pageBreak: ``,
            width: 5,
            decoration: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            color: ``,
            fontSize: 15,
          },
          {
            margin: [0, 0, 0, 0],
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
            width: `auto`,
            color: ``,
            alignment: `left`,
            text: `มี ระบุ ................................................................................................................................................................`,
            decorationColor: ``,
            bold: false,
            preserveLeadingSpaces: true,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            color: ``,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            decoration: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            bold: false,
            text: `สถานภาพการสมรส`,
            decorationStyle: ``,
            width: `auto`,
          },
          {
            decoration: ``,
            color: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            alignment: `left`,
            fontSize: 15,
            width: 10,
            text: ``,
            decorationColor: ``,
            decorationStyle: ``,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            preserveLeadingSpaces: true,
            color: ``,
            alignment: `left`,
            decorationStyle: ``,
            decoration: ``,
            decorationColor: ``,
            text: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            width: 5,
            fontSize: 15,
            pageBreak: ``,
          },
          {
            decoration: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            text: `โสด`,
            decorationColor: ``,
            color: ``,
            width: `auto`,
            pageBreak: ``,
            fontSize: 15,
            alignment: `left`,
            decorationStyle: ``,
          },
          {
            decorationStyle: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            bold: false,
            fontSize: 15,
            decoration: ``,
            pageBreak: ``,
            decorationColor: ``,
            text: ``,
            alignment: `left`,
            width: 5,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            color: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            width: 5,
            bold: false,
            fontSize: 15,
            text: ``,
            decorationColor: ``,
            alignment: `left`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
          },
          {
            text: `สมรส`,
            color: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            decoration: ``,
            decorationColor: ``,
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            width: `auto`,
          },
          {
            bold: false,
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            color: ``,
            margin: [0, 0, 0, 0],
            width: 5,
            fontSize: 15,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            bold: false,
            color: ``,
            text: ``,
            decorationColor: ``,
            pageBreak: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            width: 5,
            decorationStyle: ``,
            decoration: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            pageBreak: ``,
            alignment: `left`,
            color: ``,
            text: `แยกกันอยู่`,
            decoration: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            decorationStyle: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
          },
          {
            text: ``,
            color: ``,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: 5,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            alignment: `left`,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            decorationColor: ``,
            alignment: `left`,
            text: ``,
            decoration: ``,
            bold: false,
            color: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            width: 5,
            fontSize: 15,
            decorationStyle: ``,
            pageBreak: ``,
          },
          {
            alignment: `left`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            pageBreak: ``,
            decorationStyle: ``,
            decoration: ``,
            width: `auto`,
            text: `หย่าร้าง`,
            color: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            bold: false,
          },
          {
            decoration: ``,
            text: ``,
            alignment: `left`,
            bold: false,
            width: 5,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            decorationColor: ``,
            pageBreak: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            decoration: ``,
            text: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: 5,
            alignment: `left`,
            bold: false,
            pageBreak: ``,
            color: ``,
          },
          {
            width: `auto`,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            decorationColor: ``,
            color: ``,
            bold: false,
            preserveLeadingSpaces: true,
            alignment: `left`,
            text: `หม้าย`,
            decoration: ``,
            pageBreak: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: `auto`,
            pageBreak: ``,
            text: `ระดับการศึกษา`,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            decoration: ``,
            bold: false,
            alignment: `left`,
            decorationStyle: ``,
          },
          {
            color: ``,
            pageBreak: ``,
            alignment: `left`,
            decoration: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            width: 10,
            preserveLeadingSpaces: true,
            fontSize: 15,
            text: ``,
            decorationColor: ``,
            bold: false,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            pageBreak: ``,
            preserveLeadingSpaces: true,
            text: ``,
            color: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            bold: false,
            alignment: `left`,
            decorationStyle: ``,
            width: 5,
            fontSize: 15,
          },
          {
            margin: [0, 0, 0, 0],
            alignment: `left`,
            bold: false,
            color: ``,
            pageBreak: ``,
            text: `ยังไม่ได้เข้ารับการศึกษา`,
            fontSize: 15,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            decoration: ``,
            decorationColor: ``,
          },
          {
            text: ``,
            color: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            bold: false,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            width: 5,
            decoration: ``,
            decorationStyle: ``,
            pageBreak: ``,
            decorationColor: ``,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            fontSize: 15,
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            pageBreak: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            text: ``,
            width: 5,
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
          },
          {
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            color: ``,
            width: `auto`,
            bold: false,
            fontSize: 15,
            decoration: ``,
            decorationColor: ``,
            decorationStyle: ``,
            text: `เตรียมอนุบาล/อนุบาล`,
          },
          {
            preserveLeadingSpaces: true,
            width: 5,
            decorationStyle: ``,
            alignment: `left`,
            text: ``,
            color: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            decoration: ``,
            bold: false,
            decorationColor: ``,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            decoration: ``,
            alignment: `left`,
            text: ``,
            width: 5,
            decorationColor: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            color: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
          },
          {
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            pageBreak: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            text: `ประถมศึกษา`,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            bold: false,
            width: `auto`,
          },
          {
            preserveLeadingSpaces: true,
            color: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            bold: false,
            alignment: `left`,
            pageBreak: ``,
            width: 5,
            text: ``,
            decorationStyle: ``,
            decoration: ``,
            decorationColor: ``,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            color: ``,
            bold: false,
            alignment: `left`,
            text: ``,
            pageBreak: ``,
            width: 5,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
          },
          {
            preserveLeadingSpaces: true,
            decoration: ``,
            text: `มัธยมศึกษาตอนต้น`,
            alignment: `left`,
            pageBreak: ``,
            width: `auto`,
            decorationStyle: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            decorationColor: ``,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            bold: false,
            color: ``,
            alignment: `left`,
            decorationStyle: ``,
            pageBreak: ``,
            width: 72,
            fontSize: 15,
            decoration: ``,
            decorationColor: ``,
            text: ``,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            width: 5,
            decorationColor: ``,
            text: ``,
            decorationStyle: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            bold: false,
            decoration: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            color: ``,
          },
          {
            alignment: `left`,
            color: ``,
            pageBreak: ``,
            text: `มัธยมศึกษาตอนปลาย`,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            bold: false,
            decoration: ``,
          },
          {
            margin: [0, 0, 0, 0],
            decoration: ``,
            fontSize: 15,
            alignment: `left`,
            pageBreak: ``,
            width: 10,
            color: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: ``,
            bold: false,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            alignment: `left`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            width: 5,
            decorationColor: ``,
            text: ``,
            color: ``,
            fontSize: 15,
            decoration: ``,
            bold: false,
            decorationStyle: ``,
          },
          {
            alignment: `left`,
            text: `ปวช./ปวส./เทคนิค`,
            bold: false,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decorationColor: ``,
            pageBreak: ``,
            color: ``,
            width: `auto`,
            decoration: ``,
          },
          {
            color: ``,
            pageBreak: ``,
            decorationStyle: ``,
            width: 17,
            decorationColor: ``,
            fontSize: 15,
            decoration: ``,
            text: ``,
            preserveLeadingSpaces: true,
            bold: false,
            margin: [0, 0, 0, 0],
            alignment: `left`,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            text: ``,
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
            width: 5,
            color: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            decorationColor: ``,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
          },
          {
            text: `อนุปริญญา`,
            decorationColor: ``,
            decorationStyle: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            alignment: `left`,
            width: `auto`,
            fontSize: 15,
            color: ``,
            margin: [0, 0, 0, 0],
            bold: false,
          },
          {
            text: ``,
            alignment: `left`,
            pageBreak: ``,
            bold: false,
            color: ``,
            decorationStyle: ``,
            width: 13,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            bold: false,
            decoration: ``,
            margin: [0, 0, 0, 0],
            text: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            alignment: `left`,
            color: ``,
            decorationColor: ``,
            width: 5,
            pageBreak: ``,
            fontSize: 15,
          },
          {
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            color: ``,
            alignment: `left`,
            bold: false,
            decorationColor: ``,
            decoration: ``,
            text: `ปริญญาตรี`,
            width: `auto`,
            fontSize: 15,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            decoration: ``,
            pageBreak: ``,
            bold: false,
            alignment: `left`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: 72,
            text: ``,
            decorationStyle: ``,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            color: ``,
            fontSize: 15,
            bold: false,
            text: ``,
            width: 5,
            preserveLeadingSpaces: true,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            decorationStyle: ``,
            decorationColor: ``,
            decoration: ``,
          },
          {
            decoration: ``,
            text: `ปริญญาโท`,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            decorationStyle: ``,
            bold: false,
            alignment: `left`,
            width: `auto`,
            preserveLeadingSpaces: true,
          },
          {
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            alignment: `left`,
            text: ``,
            fontSize: 15,
            width: 55,
            decorationStyle: ``,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            color: ``,
            width: 5,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            bold: false,
            decorationStyle: ``,
            alignment: `left`,
            text: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            decoration: ``,
            decorationColor: ``,
            fontSize: 15,
            width: `auto`,
            alignment: `left`,
            bold: false,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            decorationStyle: ``,
            text: `ปริญญาเอก`,
            color: ``,
            preserveLeadingSpaces: true,
          },
          {
            bold: false,
            text: ``,
            decoration: ``,
            alignment: `left`,
            fontSize: 15,
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            width: 46,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            fontSize: 15,
            pageBreak: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            text: ``,
            decorationStyle: ``,
            alignment: `left`,
            decoration: ``,
            width: 5,
          },
          {
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            decorationStyle: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            width: `auto`,
            text: `อื่นๆ ระบุ ............................................................`,
            preserveLeadingSpaces: true,
            bold: false,
            color: ``,
            fontSize: 15,
          },
        ],
      },
      {
        alignment: `left`,
        decorationStyle: ``,
        decoration: ``,
        pageBreak: ``,
        fontSize: 15,
        color: ``,
        preserveLeadingSpaces: true,
        text: ` `,
        bold: false,
        margin: [0, 0, 0, 0],
        decorationColor: ``,
        width: `auto`,
      },
      {
        columns: [
          {
            bold: false,
            color: ``,
            width: `auto`,
            text: `ชื่อมารดาผู้ป่วย * ..........................................`,
            fontSize: 15,
            decoration: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
          },
          {
            bold: false,
            decorationStyle: ``,
            fontSize: 15,
            decoration: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            width: 5,
            text: ``,
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
          },
          {
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            width: `auto`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            color: ``,
            decorationStyle: ``,
            text: `ชื่อบิดาผู้ป่วย ...........................................`,
            decoration: ``,
            fontSize: 15,
            alignment: `left`,
          },
          {
            bold: false,
            width: 5,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            decoration: ``,
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            color: ``,
            text: ``,
          },
          {
            decorationStyle: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationColor: ``,
            bold: false,
            color: ``,
            text: `ชื่อสามี / ภรรยา ...........................................`,
            alignment: `left`,
            width: `auto`,
          },
        ],
      },
      {
        pageBreak: `before`,
        bold: false,
        decorationColor: ``,
        text: ` `,
        decorationStyle: ``,
        margin: [0, 0, 0, 0],
        width: `auto`,
        preserveLeadingSpaces: true,
        alignment: `left`,
        color: ``,
        decoration: ``,
        fontSize: 15,
      },
      {
        alignment: `left`,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        decoration: ``,
        width: `auto`,
        pageBreak: ``,
        text: `ข้อมูลติดต่อผู้ป่วย`,
        margin: [0, 0, 0, 0],
        fontSize: `16`,
        decorationStyle: ``,
        color: ``,
        bold: `true`,
      },
      {
        columns: [
          {
            fontSize: 15,
            bold: false,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            width: `auto`,
            decoration: ``,
            pageBreak: ``,
            alignment: `left`,
            text: `ที่อยู่ปัจจุบัน * บ้านเลขที่`,
            color: ``,
          },
          {
            alignment: `left`,
            decorationStyle: ``,
            text: ``,
            bold: false,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            fontSize: 15,
            width: 5,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            color: ``,
          },
          {
            stack: [
              {
                alignment: `left`,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                text:
                  props.items?.present_address?.no != null
                    ? props.items?.present_address?.no.length > 0
                      ? props.items?.present_address?.no
                      : " "
                    : " ",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
              {
                alignment: `left`,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                text: "............",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
            ],
            width: `auto`,
          },
          {
            text: ``,
            bold: false,
            width: 5,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            decoration: ``,
            color: ``,
            decorationColor: ``,
            alignment: `left`,
            pageBreak: ``,
            fontSize: 15,
          },
          {
            width: `auto`,
            fontSize: 15,
            text: `หมู่บ้าน`,
            decoration: ``,
            pageBreak: ``,
            color: ``,
            bold: false,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
          },
          {
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            pageBreak: ``,
            text: ``,
            decoration: ``,
            color: ``,
            decorationColor: ``,
            bold: false,
            width: 5,
            margin: [0, 0, 0, 0],
            fontSize: 15,
          },
          {
            stack: [
              {
                alignment: `left`,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                text:
                  props.items?.present_address?.name != null
                    ? props.items?.present_address?.name.length > 0
                      ? props.items?.present_address?.name
                      : " "
                    : " ",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
              {
                alignment: `left`,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                text: "..................................................",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
            ],
            width: `auto`,
          },
          {
            bold: false,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            text: ``,
            decorationColor: ``,
            decoration: ``,
            width: 5,
            alignment: `left`,
            pageBreak: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            color: ``,
          },
          {
            alignment: `left`,
            decoration: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            text: `หมู่`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
            bold: false,
            width: `auto`,
            decorationColor: ``,
          },
          {
            fontSize: 15,
            decorationColor: ``,
            decorationStyle: ``,
            pageBreak: ``,
            color: ``,
            decoration: ``,
            width: 5,
            preserveLeadingSpaces: true,
            bold: false,
            margin: [0, 0, 0, 0],
            text: ``,
            alignment: `left`,
          },
          {
            stack: [
              {
                alignment: `left`,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                text:
                  props.items?.present_address?.town != null
                    ? props.items?.present_address?.town.length > 0
                      ? props.items?.present_address?.town
                      : " "
                    : " ",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
              {
                alignment: `left`,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                text: "........",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
            ],
            width: `auto`,
          },
          {
            decorationStyle: ``,
            decoration: ``,
            text: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            fontSize: 15,
            pageBreak: ``,
            width: 5,
            preserveLeadingSpaces: true,
            bold: false,
            alignment: `left`,
            color: ``,
          },
          {
            pageBreak: ``,
            width: `auto`,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            text: `ซอย`,
            alignment: `left`,
            bold: false,
            decoration: ``,
            fontSize: 15,
            color: ``,
            decorationColor: ``,
          },
          {
            text: ``,
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            color: ``,
            decorationStyle: ``,
            width: 5,
          },
          {
            stack: [
              {
                alignment: `left`,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                text:
                  props.items?.present_address?.street != null
                    ? props.items?.present_address?.street.length > 0
                      ? props.items?.present_address?.street
                      : " "
                    : " ",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
              {
                alignment: `left`,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                text: "........",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
            ],
            width: `auto`,
          },
          {
            pageBreak: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            width: 5,
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            bold: false,
            decoration: ``,
            text: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
          {
            bold: false,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            text: `ถนน`,
            fontSize: 15,
            pageBreak: ``,
            color: ``,
            decorationStyle: ``,
            width: `auto`,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
          },
          {
            alignment: `left`,
            width: 5,
            preserveLeadingSpaces: true,
            bold: false,
            decorationStyle: ``,
            pageBreak: ``,
            text: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            color: ``,
            decorationColor: ``,
            fontSize: 15,
          },
          {
            stack: [
              {
                alignment: `left`,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                text:
                  props.items?.present_address?.road != null
                    ? props.items?.present_address?.road.length > 0
                      ? props.items?.present_address?.road
                      : " "
                    : " ",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
              {
                alignment: `left`,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                text: "...............................",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
            ],
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            margin: [0, 0, 0, 0],
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            alignment: `left`,
            text: `แขวง / ตำบล`,
            bold: false,
            color: ``,
          },
          {
            decoration: ``,
            decorationColor: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            bold: false,
            fontSize: 15,
            text: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            alignment: `left`,
            color: ``,
            width: 5,
          },
          {
            stack: [
              {
                alignment: `left`,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                text:
                  props.items?.present_address?.city_label != null
                    ? props.items?.present_address?.city_label.length > 0
                      ? props.items?.present_address?.city_label
                      : " "
                    : " ",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
              {
                alignment: `left`,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                text: "........................",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
            ],
            width: `auto`,
          },
          {
            preserveLeadingSpaces: true,
            text: ``,
            bold: false,
            alignment: `left`,
            color: ``,
            width: 5,
            decorationColor: ``,
            decorationStyle: ``,
            pageBreak: ``,
            decoration: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
          },
          {
            color: ``,
            text: `เขต / อำเภอ`,
            alignment: `left`,
            width: `auto`,
            fontSize: 15,
            bold: false,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decoration: ``,
          },
          {
            color: ``,
            decorationColor: ``,
            decoration: ``,
            width: 5,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            text: ``,
            decorationStyle: ``,
            bold: false,
            alignment: `left`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
          {
            stack: [
              {
                alignment: `left`,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                text:
                  props.items?.present_address?.district_label != null
                    ? props.items?.present_address?.district_label.length > 0
                      ? props.items?.present_address?.district_label
                      : " "
                    : " ",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
              {
                alignment: `left`,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                text: "........................",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
            ],
            width: `auto`,
          },
          {
            bold: false,
            color: ``,
            decorationColor: ``,
            width: 5,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            alignment: `left`,
            text: ``,
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            bold: false,
            color: ``,
            text: `จังหวัด`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
          },
          {
            alignment: `left`,
            decorationStyle: ``,
            bold: false,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            width: 5,
            text: ``,
            color: ``,
            decoration: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
          },
          {
            stack: [
              {
                alignment: `left`,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                text:
                  props.items?.present_address?.province_label != null
                    ? props.items?.present_address?.province_label.length > 0
                      ? props.items?.present_address?.province_label
                      : " "
                    : " ",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
              {
                alignment: `left`,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                text: "..............................",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
            ],
            width: `auto`,
          },
          {
            decorationColor: ``,
            fontSize: 15,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            text: ``,
            preserveLeadingSpaces: true,
            width: 5,
            color: ``,
            decoration: ``,
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
          },
          {
            text: `รหัสไปรษณีย์`,
            decorationStyle: ``,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            color: ``,
            pageBreak: ``,
            alignment: `left`,
            width: `auto`,
            preserveLeadingSpaces: true,
          },
          {
            bold: false,
            width: 5,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            decoration: ``,
            text: ``,
            fontSize: 15,
            alignment: `left`,
            color: ``,
            decorationColor: ``,
          },
          { image: "squarebox", margin: [0, 0, 0, 0], width: 15 },
          {
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            fontSize: 15,
            decoration: ``,
            color: ``,
            decorationColor: ``,
            text: ``,
            width: 2,
            bold: false,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
          },
          { image: "squarebox", margin: [0, 0, 0, 0], width: 15 },
          {
            margin: [0, 0, 0, 0],
            width: 2,
            decorationStyle: ``,
            color: ``,
            fontSize: 15,
            bold: false,
            decoration: ``,
            alignment: `left`,
            pageBreak: ``,
            text: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
          },
          { image: "squarebox", margin: [0, 0, 0, 0], width: 15 },
          {
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            decorationColor: ``,
            bold: false,
            color: ``,
            decoration: ``,
            width: 2,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            alignment: `left`,
            text: ``,
          },
          { image: "squarebox", margin: [0, 0, 0, 0], width: 15 },
          {
            decorationStyle: ``,
            alignment: `left`,
            width: 2,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            color: ``,
            text: ``,
            decoration: ``,
            fontSize: 15,
            decorationColor: ``,
            bold: false,
          },
          { image: "squarebox", margin: [0, 0, 0, 0], width: 15 },
          // Fill Zipcode in the Box
          {
            text: props.items?.zipcode_split[0],
            fontSize: 15,
            absolutePosition: { x: 451, y: 183 },
          },
          {
            text: props.items?.zipcode_split[1],
            fontSize: 15,
            absolutePosition: { x: 468, y: 183 },
          },
          {
            text: props.items?.zipcode_split[2],
            fontSize: 15,
            absolutePosition: { x: 485, y: 183 },
          },
          {
            text: props.items?.zipcode_split[3],
            fontSize: 15,
            absolutePosition: { x: 502, y: 183 },
          },
          {
            text: props.items?.zipcode_split[4],
            fontSize: 15,
            absolutePosition: { x: 519, y: 183 },
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            text: `อีเมล์`,
            pageBreak: ``,
            fontSize: 15,
            bold: false,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            color: ``,
            width: `auto`,
            decorationColor: ``,
          },
          {
            decorationColor: ``,
            fontSize: 15,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            text: ``,
            preserveLeadingSpaces: true,
            width: 5,
            color: ``,
            decoration: ``,
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
          },
          {
            stack: [
              {
                alignment: `left`,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                text:
                  props.items?.present_address?.email != null
                    ? props.items?.present_address?.email.length > 0
                      ? props.items?.present_address?.email
                      : " "
                    : " ",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
              {
                alignment: `left`,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                text: "........................................................................",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
            ],
            width: `auto`,
          },
          {
            decorationColor: ``,
            fontSize: 15,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            text: ``,
            preserveLeadingSpaces: true,
            width: 5,
            color: ``,
            decoration: ``,
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
          },
          {
            bold: false,
            decorationColor: ``,
            text: ` ช่องทางการส่งข้อมูลการรักษา`,
            width: `auto`,
            pageBreak: ``,
            fontSize: 15,
            alignment: `left`,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
          },
          {
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            decoration: ``,
            alignment: `left`,
            pageBreak: ``,
            text: ``,
            width: 5,
            color: ``,
            decorationColor: ``,
          },
          {
            image: props.items?.present_address?.channel === "1" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
          },
          {
            decorationStyle: ``,
            fontSize: 15,
            color: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            pageBreak: ``,
            text: ``,
            alignment: `left`,
            width: 5,
            preserveLeadingSpaces: true,
            bold: false,
          },
          {
            pageBreak: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            decoration: ``,
            color: ``,
            text: `อีเมล์`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationColor: ``,
            bold: false,
            width: `auto`,
            decorationStyle: ``,
          },
          {
            decorationColor: ``,
            decoration: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            width: 5,
            preserveLeadingSpaces: true,
            alignment: `left`,
            pageBreak: ``,
            decorationStyle: ``,
            text: ``,
            bold: false,
            color: ``,
          },
          {
            image: props.items?.present_address?.channel === "2" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
          },
          {
            decorationColor: ``,
            decoration: ``,
            text: ``,
            fontSize: 15,
            pageBreak: ``,
            bold: false,
            width: 5,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            color: ``,
          },
          {
            preserveLeadingSpaces: true,
            color: ``,
            bold: false,
            decoration: ``,
            text: `จดหมาย`,
            decorationStyle: ``,
            fontSize: 15,
            width: `auto`,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            pageBreak: ``,
            alignment: `left`,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            pageBreak: ``,
            color: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            alignment: `left`,
            fontSize: 15,
            decorationStyle: ``,
            text: `โทรศัพท์ *`,
            bold: false,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
          },
          {
            fontSize: 15,
            bold: false,
            text: ``,
            width: 10,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            pageBreak: ``,
          },
          {
            decorationStyle: ``,
            decoration: ``,
            decorationColor: ``,
            bold: false,
            color: ``,
            width: `auto`,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            preserveLeadingSpaces: true,
            text: `บ้าน`,
            fontSize: 15,
          },
          {
            decorationColor: ``,
            fontSize: 15,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            text: ``,
            preserveLeadingSpaces: true,
            width: 5,
            color: ``,
            decoration: ``,
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
          },
          {
            stack: [
              {
                alignment: `left`,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                text:
                  props.items?.present_address?.tel_home != null
                    ? props.items?.present_address?.tel_home.length > 0
                      ? props.items?.present_address?.tel_home
                      : " "
                    : " ",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
              {
                alignment: `left`,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                text: ".........................................................................",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
            ],
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            margin: [0, 0, 0, 0],
            text: ``,
            decorationStyle: ``,
            fontSize: 15,
            color: ``,
            bold: false,
            decoration: ``,
            pageBreak: ``,
            width: 52,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            alignment: `left`,
          },
          {
            width: `auto`,
            alignment: `left`,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            decoration: ``,
            color: ``,
            decorationStyle: ``,
            bold: false,
            pageBreak: ``,
            text: `ที่ทำงาน`,
            preserveLeadingSpaces: true,
          },
          {
            fontSize: 15,
            width: 5,
            text: ``,
            color: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            alignment: `left`,
            decorationColor: ``,
            bold: false,
            decoration: ``,
            margin: [0, 0, 0, 0],
          },
          {
            stack: [
              {
                alignment: `left`,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                text:
                  props.items?.present_address?.tel_office != null
                    ? props.items?.present_address?.tel_office.length > 0
                      ? props.items?.present_address?.tel_office
                      : " "
                    : " ",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
              {
                alignment: `left`,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                text: "..................................................................",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
            ],
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            margin: [0, 0, 0, 0],
            decoration: ``,
            width: 52,
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            color: ``,
            text: ``,
            alignment: `left`,
            bold: false,
            fontSize: 15,
          },
          {
            decoration: ``,
            decorationStyle: ``,
            color: ``,
            fontSize: 15,
            decorationColor: ``,
            text: `โทรศัพท์`,
            preserveLeadingSpaces: true,
            bold: false,
            width: `auto`,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            alignment: `left`,
          },
          {
            decoration: ``,
            text: ``,
            preserveLeadingSpaces: true,
            width: 5,
            fontSize: 15,
            decorationStyle: ``,
            bold: false,
            color: ``,
            alignment: `left`,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
          },
          {
            stack: [
              {
                alignment: `left`,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                text:
                  props.items?.present_address?.tel_mobile != null
                    ? props.items?.present_address?.tel_mobile.length > 0
                      ? props.items?.present_address?.tel_mobile
                      : " "
                    : " ",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
              {
                alignment: `left`,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                text: "..................................................................",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
            ],
            width: `auto`,
          },
        ],
      },
      {
        decorationStyle: ``,
        alignment: `left`,
        color: ``,
        pageBreak: ``,
        fontSize: 15,
        decoration: ``,
        text: ` `,
        width: `auto`,
        bold: false,
        margin: [0, 0, 0, 0],
        preserveLeadingSpaces: true,
        decorationColor: ``,
      },
      {
        columns: [
          {
            alignment: `left`,
            fontSize: `16`,
            preserveLeadingSpaces: true,
            text: `ข้อมูลติดต่อญาติผู้ป่วย`,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            width: `auto`,
            decoration: ``,
            bold: `true`,
          },
          {
            margin: [0, 0, 0, 0],
            width: 20,
            decorationColor: ``,
            pageBreak: ``,
            fontSize: 15,
            decoration: ``,
            color: ``,
            bold: false,
            preserveLeadingSpaces: true,
            text: ``,
            alignment: `left`,
            decorationStyle: ``,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            color: ``,
            text: ``,
            width: 5,
            decorationColor: ``,
            alignment: `left`,
            decorationStyle: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            decoration: ``,
            preserveLeadingSpaces: true,
            bold: false,
          },
          {
            fontSize: 15,
            alignment: `left`,
            color: ``,
            decoration: ``,
            text: `ที่อยู่เดียวกันกับผู้ป่วย`,
            decorationStyle: ``,
            pageBreak: ``,
            width: `auto`,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            bold: false,
            preserveLeadingSpaces: true,
          },
          {
            pageBreak: ``,
            width: 5,
            decorationColor: ``,
            text: ``,
            fontSize: 15,
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            color: ``,
            alignment: `left`,
          },
          {
            bold: false,
            alignment: `left`,
            width: `auto`,
            decorationColor: ``,
            fontSize: `14`,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            text: `(หากเลือกตัวเลือกนี้ไม่ต้องกรอกที่อยู่ติดต่อญาติผู้ป่วย)`,
            decoration: ``,
            color: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            text: `บุคคลที่ติดต่อกรณีฉุกเฉิน *  ชื่อ `,
            color: ``,
            pageBreak: ``,
            width: `auto`,
            decorationColor: ``,
            decorationStyle: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            bold: false,
          },
          {
            alignment: `left`,
            pageBreak: ``,
            color: ``,
            text: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            width: 5,
            decorationColor: ``,
            bold: false,
            decoration: ``,
            decorationStyle: ``,
            fontSize: 15,
          },
          {
            stack: [
              {
                alignment: `left`,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                text: props.items?.owner_split[0] ? props.items?.owner_split[0] : " ",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
              {
                alignment: `left`,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                text: "..............................",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
            ],
            width: `auto`,
          },
          {
            bold: false,
            decoration: ``,
            pageBreak: ``,
            decorationColor: ``,
            fontSize: 15,
            text: ``,
            width: 15,
            color: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationStyle: ``,
          },
          {
            decorationStyle: ``,
            pageBreak: ``,
            fontSize: 15,
            width: `auto`,
            bold: false,
            decoration: ``,
            alignment: `left`,
            color: ``,
            preserveLeadingSpaces: true,
            text: `สกุล`,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
          },
          {
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            alignment: `left`,
            decorationStyle: ``,
            text: ``,
            fontSize: 15,
            decoration: ``,
            bold: false,
            decorationColor: ``,
            width: 5,
            preserveLeadingSpaces: true,
          },
          {
            stack: [
              {
                alignment: `left`,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                text: props.items?.owner_split[1] ? props.items?.owner_split[1] : " ",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
              {
                alignment: `left`,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                text: "..............................",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
            ],
            width: `auto`,
          },
          {
            pageBreak: ``,
            text: ``,
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            bold: false,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            color: ``,
            width: 15,
            alignment: `left`,
          },
          {
            preserveLeadingSpaces: true,
            pageBreak: ``,
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            decorationColor: ``,
            text: `ความสัมพันธ์`,
            decorationStyle: ``,
            decoration: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
          },
          {
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            alignment: `left`,
            decorationStyle: ``,
            text: ``,
            fontSize: 15,
            decoration: ``,
            bold: false,
            decorationColor: ``,
            width: 5,
            preserveLeadingSpaces: true,
          },
          {
            stack: [
              {
                alignment: `left`,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                text:
                  props.items?.relative_address?.relative.length > 0
                    ? props.items?.relative_address?.relative
                    : " ",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
              {
                alignment: `left`,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                text: ".................................",
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decoration: ``,
              },
            ],
            width: `auto`,
          },
          {
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            alignment: `left`,
            decorationStyle: ``,
            text: ``,
            fontSize: 15,
            decoration: ``,
            bold: false,
            decorationColor: ``,
            width: 5,
            preserveLeadingSpaces: true,
          },
          {
            preserveLeadingSpaces: true,
            pageBreak: ``,
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            decorationColor: ``,
            text: `ของผู้ป่วย`,
            decorationStyle: ``,
            decoration: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            decorationColor: ``,
            decoration: ``,
            bold: false,
            alignment: `left`,
            pageBreak: ``,
            fontSize: 15,
            decorationStyle: ``,
            width: `auto`,
            text: `ที่อยู่ปัจจุบัน บ้านเลขที่ ......................`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            decorationColor: ``,
            fontSize: 15,
            bold: false,
            text: ``,
            decorationStyle: ``,
            width: 5,
            color: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
          },
          {
            decoration: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            color: ``,
            width: `auto`,
            fontSize: 15,
            text: `หมู่บ้าน .....................................................`,
            decorationStyle: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            alignment: `left`,
          },
          {
            text: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            alignment: `left`,
            bold: false,
            margin: [0, 0, 0, 0],
            color: ``,
            width: 5,
            pageBreak: ``,
            decorationStyle: ``,
            fontSize: 15,
            decorationColor: ``,
          },
          {
            alignment: `left`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            text: `หมู่ ............................`,
            color: ``,
            fontSize: 15,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            bold: false,
            decorationStyle: ``,
            width: `auto`,
          },
          {
            text: ``,
            preserveLeadingSpaces: true,
            bold: false,
            fontSize: 15,
            decoration: ``,
            width: 5,
            decorationColor: ``,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
          },
          {
            text: `ซอย ...................................`,
            decorationStyle: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            fontSize: 15,
            decoration: ``,
            width: `auto`,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
            width: `auto`,
            decorationStyle: ``,
            alignment: `left`,
            color: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            text: `แขวง / ตำบล ................................................`,
          },
          {
            width: 5,
            margin: [0, 0, 0, 0],
            decoration: ``,
            color: ``,
            text: ``,
            bold: false,
            fontSize: 15,
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationStyle: ``,
          },
          {
            text: `เขต / อำเภอ  ................................................`,
            margin: [0, 0, 0, 0],
            bold: false,
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            alignment: `left`,
            width: `auto`,
          },
          {
            bold: false,
            text: ``,
            preserveLeadingSpaces: true,
            width: 5,
            decorationColor: ``,
            pageBreak: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            decorationStyle: ``,
            fontSize: 15,
            alignment: `left`,
          },
          {
            width: `auto`,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            decoration: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            fontSize: 15,
            text: `จังหวัด  ...................................................`,
            alignment: `left`,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            preserveLeadingSpaces: true,
            alignment: `left`,
            pageBreak: ``,
            width: `auto`,
            decoration: ``,
            text: `รหัสไปรษณีย์`,
            color: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            decorationStyle: ``,
          },
          {
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            color: ``,
            decoration: ``,
            text: ``,
            decorationColor: ``,
            decorationStyle: ``,
            bold: false,
            pageBreak: ``,
            alignment: `left`,
            width: 5,
          },
          { image: "squarebox", margin: [0, 0, 0, 0], width: 15 },
          {
            fontSize: 15,
            width: 2,
            text: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decoration: ``,
            bold: false,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            color: ``,
            pageBreak: ``,
          },
          { image: "squarebox", margin: [0, 0, 0, 0], width: 15 },
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            bold: false,
            color: ``,
            fontSize: 15,
            alignment: `left`,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            width: 2,
            pageBreak: ``,
            text: ``,
            decoration: ``,
          },
          { image: "squarebox", margin: [0, 0, 0, 0], width: 15 },
          {
            decoration: ``,
            text: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            fontSize: 15,
            alignment: `left`,
            color: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decorationColor: ``,
            width: 2,
            pageBreak: ``,
          },
          { image: "squarebox", margin: [0, 0, 0, 0], width: 15 },
          {
            width: 2,
            color: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            pageBreak: ``,
            decoration: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            text: ``,
            decorationColor: ``,
            decorationStyle: ``,
            fontSize: 15,
          },
          { image: "squarebox", margin: [0, 0, 0, 0], width: 15 },
          {
            text: ``,
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
            bold: false,
            decoration: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            preserveLeadingSpaces: true,
            width: 15,
            decorationColor: ``,
          },
          {
            alignment: `left`,
            color: ``,
            decorationStyle: ``,
            pageBreak: ``,
            decoration: ``,
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            width: `auto`,
            decorationColor: ``,
            text: `อีเมล์ .............................................................................................................................`,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            alignment: `left`,
            bold: false,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            text: `โทรศัพท์ *`,
            width: `auto`,
            decorationStyle: ``,
            color: ``,
          },
          {
            decoration: ``,
            fontSize: 15,
            pageBreak: ``,
            decorationColor: ``,
            width: 10,
            color: ``,
            bold: false,
            text: ``,
            alignment: `left`,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
          },
          {
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decoration: ``,
            text: `บ้าน .........................................................................`,
            color: ``,
            alignment: `left`,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            width: `auto`,
            bold: false,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            text: ``,
            bold: false,
            width: 52,
            decoration: ``,
            pageBreak: ``,
            color: ``,
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
          },
          {
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
            text: `ที่ทำงาน`,
            width: `auto`,
            color: ``,
            decorationColor: ``,
            pageBreak: ``,
            alignment: `left`,
            decoration: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
          },
          {
            text: ``,
            alignment: `left`,
            decoration: ``,
            color: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            fontSize: 15,
            width: 5,
            pageBreak: ``,
          },
          {
            alignment: `left`,
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            bold: false,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            text: `..................................................................`,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: 52,
            bold: false,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            fontSize: 15,
            color: ``,
            text: ``,
            alignment: `left`,
            decoration: ``,
            decorationStyle: ``,
          },
          {
            text: `โทรศัพท์`,
            decorationColor: ``,
            decorationStyle: ``,
            width: `auto`,
            pageBreak: ``,
            color: ``,
            alignment: `left`,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decoration: ``,
            bold: false,
          },
          {
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            decorationStyle: ``,
            text: ``,
            color: ``,
            bold: false,
            fontSize: 15,
            alignment: `left`,
            decoration: ``,
            width: 5,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
          {
            alignment: `left`,
            color: ``,
            bold: false,
            decoration: ``,
            text: `..................................................................`,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            fontSize: 15,
            pageBreak: ``,
          },
        ],
      },
      {
        text: ` `,
        margin: [0, 0, 0, 0],
        decorationColor: ``,
        decorationStyle: ``,
        bold: false,
        decoration: ``,
        alignment: `left`,
        width: `auto`,
        pageBreak: ``,
        fontSize: 15,
        preserveLeadingSpaces: true,
        color: ``,
      },
      {
        margin: [0, 0, 0, 0],
        decorationColor: ``,
        color: ``,
        alignment: `left`,
        bold: false,
        pageBreak: ``,
        text: `ข้าพเจ้าขอรับรองว่า ข้อมูลทั้งหมดนี้ถูกต้องตรงกับความจริงทุกประการและแสดงเจตนายินยอม ดังต่อไปนี้`,
        width: `auto`,
        fontSize: 15,
        decoration: ``,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
      },
      {
        columns: [
          {
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            width: 15,
            decoration: ``,
            text: ``,
            pageBreak: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            bold: false,
          },
          {
            pageBreak: ``,
            fontSize: 15,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            width: `auto`,
            alignment: `left`,
            decoration: ``,
            color: ``,
            bold: false,
            text: `1.`,
            decorationStyle: ``,
          },
          {
            fontSize: 15,
            text: ` โรงพยาบาลยินดีที่จะให้บริการตามคำประกาศสิทธิและข้อพึงปฎิบัติของผู้ป่วย`,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            decorationColor: ``,
            decoration: ``,
            width: `auto`,
            bold: `true`,
            color: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            alignment: `left`,
          },
          {
            alignment: `left`,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
            decoration: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            text: ` ที่ประกาศ ณ วันที่ 12 สิงหาคม พ.ศ. 2558`,
            pageBreak: ``,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            fontSize: 15,
            text: ``,
            decorationStyle: ``,
            width: 15,
            color: ``,
            pageBreak: ``,
            decoration: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
          },
          {
            decorationColor: ``,
            fontSize: 15,
            color: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            bold: false,
            alignment: `left`,
            text: `2.`,
            decorationStyle: ``,
            pageBreak: ``,
          },
          {
            color: ``,
            width: `auto`,
            fontSize: 15,
            decorationStyle: ``,
            text: ` การยินยอมรับการรักษา: `,
            margin: [0, 0, 0, 0],
            bold: `true`,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationColor: ``,
            pageBreak: ``,
            decoration: ``,
          },
          {
            decoration: ``,
            text: ` ข้าพเจ้ายินยอมให้แพทย์ พยาบาล นักศึกษา และบุคลากรทางการแพทย์ของ`,
            bold: false,
            decorationColor: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            width: `auto`,
            preserveLeadingSpaces: true,
            color: ``,
            pageBreak: ``,
            decorationStyle: ``,
            alignment: `left`,
          },
          {
            pageBreak: ``,
            text: ``,
            bold: false,
            decoration: ``,
            decorationStyle: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            color: ``,
            fontSize: 15,
            decorationColor: ``,
            width: 5,
          },
          {
            color: ``,
            pageBreak: ``,
            bold: false,
            alignment: `left`,
            text: props.items?.hospital,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            fontSize: 15,
          },
        ],
      },
      {
        alignment: `left`,
        preserveLeadingSpaces: true,
        bold: false,
        margin: [0, 0, 0, 0],
        decoration: ``,
        color: ``,
        decorationColor: ``,
        text: `ทำการดูแลรักษาทั่วไปแบบผู้ป่วยนอก และในการนำข้อมูลประวัติทางการแพทย์ของข้าพเจ้า อาทิเช่น ประวัติการรักษา และภาพรังสี `,
        width: `auto`,
        fontSize: 15,
        decorationStyle: ``,
        pageBreak: ``,
      },
      {
        decorationStyle: ``,
        width: `auto`,
        margin: [0, 0, 0, 0],
        decoration: ``,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        color: ``,
        fontSize: 15,
        bold: false,
        pageBreak: ``,
        alignment: `left`,
        text: `เพื่อนำไปใช้ในการประกอบการเรียน การสอน การศึกษา วิจัย เผยแพร่โดยวิธีอื่นใดเพื่อประโยชน์ทางวิชาการอันเป็นประโยชน์ในทาง `,
      },
      {
        columns: [
          {
            alignment: `left`,
            decoration: ``,
            bold: false,
            decorationStyle: ``,
            fontSize: 15,
            text: `การแพทย์ หรือการพัฒนาคุณภาพของ`,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            decorationColor: ``,
          },
          {
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            width: 5,
            bold: false,
            fontSize: 15,
            decoration: ``,
            decorationColor: ``,
            decorationStyle: ``,
            text: ``,
            color: ``,
            pageBreak: ``,
          },
          {
            text: props.items?.hospital,
            decorationStyle: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            width: `auto`,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            decoration: ``,
            color: ``,
            fontSize: 15,
            bold: false,
          },
          {
            pageBreak: ``,
            decorationColor: ``,
            text: ` หากต้องการความเป็นส่วนตัวในการให้ข้อมูลการดูแลรักษา โดยไม่มี`,
            color: ``,
            fontSize: 15,
            decoration: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            bold: false,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
          },
        ],
      },
      {
        decorationColor: ``,
        color: ``,
        decoration: ``,
        decorationStyle: ``,
        alignment: `left`,
        bold: false,
        margin: [0, 0, 0, 0],
        pageBreak: ``,
        text: `บุคคลอื่น ให้แจ้งความประสงค์กับเจ้าหน้าที่หรือแพทย์ผู้ดูแล`,
        preserveLeadingSpaces: true,
        fontSize: 15,
        width: `auto`,
      },
      {
        columns: [
          {
            alignment: `left`,
            color: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            text: ``,
            width: 15,
            bold: false,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationStyle: ``,
          },
          {
            decorationColor: ``,
            fontSize: 15,
            text: `3.`,
            alignment: `left`,
            bold: false,
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            color: ``,
            decorationStyle: ``,
            width: `auto`,
            margin: [0, 0, 0, 0],
          },
          {
            pageBreak: ``,
            bold: `true`,
            decorationColor: ``,
            decoration: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            text: ` การยินยอมใช้รูปภาพ:`,
            preserveLeadingSpaces: true,
            alignment: `left`,
            fontSize: 15,
            width: `auto`,
          },
          {
            color: ``,
            decorationColor: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            width: `auto`,
            fontSize: 15,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            text: ` ข้าพเจ้ายินยอมให้`,
            decorationStyle: ``,
            decoration: ``,
            bold: false,
          },
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: 5,
            pageBreak: ``,
            decorationStyle: ``,
            text: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            bold: false,
            decorationColor: ``,
            decoration: ``,
            color: ``,
          },
          {
            decoration: ``,
            pageBreak: ``,
            width: `auto`,
            decorationStyle: ``,
            alignment: `left`,
            bold: false,
            fontSize: 15,
            text: props.items?.hospital,
            preserveLeadingSpaces: true,
            color: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
          },
          {
            pageBreak: ``,
            text: ``,
            alignment: `left`,
            decoration: ``,
            width: 5,
            decorationColor: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
          },
          {
            pageBreak: ``,
            bold: false,
            alignment: `left`,
            decoration: ``,
            text: `ตรวจสอบจากฐานข้อมูลทางทะเบียนใดๆ ของรัฐ รวมถึง`,
            decorationColor: ``,
            color: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: `auto`,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        fontSize: 15,
        text: `อนุญาตให้ใช้รูปภาพและข้อมูลประวัติของข้าพเจ้า เพื่อการมีเวชระเบียนและการตรวจรักษา หากมีข้อมูลใดไม่ถูกต้อง หรือไม่ตรง`,
        width: `auto`,
        pageBreak: ``,
        color: ``,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        bold: false,
        decoration: ``,
        margin: [0, 0, 0, 0],
        alignment: `left`,
        decorationColor: ``,
      },
      {
        bold: false,
        pageBreak: ``,
        color: ``,
        margin: [0, 0, 0, 0],
        fontSize: 15,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        decoration: ``,
        alignment: `left`,
        width: `auto`,
        text: `กับความจริงและอาจจะทำให้เกิดความเสียหายแก่ตัวข้าพเจ้าหรือบุคคลอื่น ข้าพเจ้ายินยอมรับผิดชอบ ในความเสียหายที่เกิดขึ้น `,
        decorationStyle: ``,
      },
      {
        text: `ทุกประการ`,
        alignment: `left`,
        fontSize: 15,
        decorationColor: ``,
        margin: [0, 0, 0, 0],
        width: `auto`,
        bold: false,
        pageBreak: ``,
        decoration: ``,
        color: ``,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
      },
      {
        columns: [
          {
            text: ``,
            width: 15,
            alignment: `left`,
            decoration: ``,
            bold: false,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            pageBreak: ``,
            fontSize: 15,
          },
          {
            decoration: ``,
            preserveLeadingSpaces: true,
            color: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            width: `auto`,
            fontSize: 15,
            alignment: `left`,
            pageBreak: ``,
            decorationStyle: ``,
            text: `4. เอกสารฉบับนี้ได้รวมถึงการยินยอมให้แพทย์และบุคลากรทางการแพทย์ทำการดูแลรักษาทั่วไปแบบผู้ป่วยนอก ได้แก่ การตรวจ`,
            decorationColor: ``,
          },
        ],
      },
      {
        preserveLeadingSpaces: true,
        bold: false,
        decorationStyle: ``,
        fontSize: 15,
        decoration: ``,
        pageBreak: ``,
        alignment: `left`,
        text: `ร่างกายตามความจำเป็นทางการแพทย์ การส่งตรวจวินิจฉัยทั่วไปทางห้องปฏิบัติการ ยกเว้นการตรวจหาการติดเชื้อเอชไอวี ซึ่งต้องได้ `,
        margin: [0, 0, 0, 0],
        color: ``,
        decorationColor: ``,
        width: `auto`,
      },
      {
        margin: [0, 0, 0, 0],
        fontSize: 15,
        preserveLeadingSpaces: true,
        color: ``,
        alignment: `left`,
        pageBreak: ``,
        decorationStyle: ``,
        decorationColor: ``,
        bold: false,
        decoration: ``,
        text: `รับความยินยอมแยกเฉพาะ ทั้งนี้หากเป็นการรักษาที่มีความเสี่ยงสูงหรือต้องได้รับการบอกกล่าวเฉพาะเรื่อง เช่น การผ่าตัด/หัตถการ `,
        width: `auto`,
      },
      {
        preserveLeadingSpaces: true,
        bold: false,
        decoration: ``,
        alignment: `left`,
        decorationColor: ``,
        text: `การให้ยาละลายลิ่มเลือดทางหลอดเลือดดำ ทางโรงพยาบาลจะทำการขอความยินยอมก่อนการดูแลรักษา เพื่อให้ท่านได้มีส่วนรวมใน `,
        fontSize: 15,
        color: ``,
        decorationStyle: ``,
        width: `auto`,
        margin: [0, 0, 0, 0],
        pageBreak: ``,
      },
      {
        fontSize: 15,
        decorationStyle: ``,
        width: `auto`,
        color: ``,
        preserveLeadingSpaces: true,
        text: `การตัดสินใจและแสดงความยินยอมแยกเฉพาะ โดยท่านสามารถขอดูรายการที่ต้องได้รับการขอความยินยอมแยกเฉพาะได้ที่จุดลง `,
        decorationColor: ``,
        margin: [0, 0, 0, 0],
        pageBreak: ``,
        bold: false,
        decoration: ``,
        alignment: `left`,
      },
      {
        text: `ทะเบียนเคาน์เตอร์พยาบาล และในห้องพักผู้ป่วย`,
        color: ``,
        decorationColor: ``,
        bold: false,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        decorationStyle: ``,
        width: `auto`,
        margin: [0, 0, 0, 0],
        fontSize: 15,
        decoration: ``,
        alignment: `left`,
      },
      {
        columns: [
          {
            width: 15,
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
            bold: false,
            text: ``,
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            color: ``,
            alignment: `left`,
          },
          {
            text: `5.`,
            alignment: `left`,
            decorationStyle: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decoration: ``,
            width: `auto`,
            color: ``,
            pageBreak: ``,
          },
          {
            text: ` โรงพยาบาลจะให้การเรียก ชื่อ - สกุล ของผูรับบริการ กรณีที่ไม่ประสงค์ให้เจ้าหน้าที่เรียก ชื่อ - สกุล เพื่อเข้ารับบริการ หรือ`,
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            width: `auto`,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            decorationColor: ``,
            pageBreak: ``,
            bold: false,
            decorationStyle: ``,
            fontSize: 15,
          },
        ],
      },
      {
        decorationStyle: ``,
        fontSize: 15,
        width: `auto`,
        decoration: ``,
        alignment: `left`,
        bold: false,
        pageBreak: ``,
        color: ``,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
        text: `ต้องการให้ปกปิดการเข้ารับบริการ กรุณาแจ้งให้เจ้าหน้าที่ผู้ให้บริการทราบ`,
      },
      {
        columns: [
          {
            color: ``,
            pageBreak: ``,
            fontSize: 15,
            decoration: ``,
            alignment: `left`,
            decorationStyle: ``,
            bold: false,
            text: ``,
            margin: [0, 0, 0, 0],
            width: 15,
            decorationColor: ``,
            preserveLeadingSpaces: true,
          },
          {
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
            decorationColor: ``,
            color: ``,
            text: `6.`,
            width: `auto`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            bold: false,
            margin: [0, 0, 0, 0],
            decoration: ``,
          },
          {
            text: ` ทางโรงพยาบาลขอเรียนให้ท่านทราบว่าทางโรงพยาบาลได้มีบุคลากรที่อยู่ในระหว่างฝึกอบรม มาศึกษาดูงาน ซึ่งท่านสามารถ`,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            fontSize: 15,
            width: `auto`,
            pageBreak: ``,
            color: ``,
            decorationStyle: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            bold: false,
          },
        ],
      },
      {
        fontSize: 15,
        decorationStyle: ``,
        width: `auto`,
        alignment: `left`,
        preserveLeadingSpaces: true,
        decoration: ``,
        margin: [0, 0, 0, 0],
        color: ``,
        pageBreak: ``,
        bold: false,
        text: `สังเกตได้จากบัตรประจำตัวบุคลากร โดยบุคลากรดังกล่าวจะได้รับการปฐมนิเทศกฎระเบียบและแนวทางปฏิบัติต่างๆ รวมถึงมีผู้กำกับ `,
        decorationColor: ``,
      },
      {
        pageBreak: ``,
        alignment: `left`,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        fontSize: 15,
        margin: [0, 0, 0, 0],
        decoration: ``,
        decorationColor: ``,
        color: ``,
        width: `auto`,
        text: `ดูแลการปฏิบัติงานในขอบเขตที่กำหนดให้อย่างเคร่งครัด`,
        bold: false,
      },
      {
        width: `auto`,
        pageBreak: ``,
        bold: false,
        decoration: ``,
        decorationColor: ``,
        margin: [0, 0, 0, 0],
        fontSize: 15,
        text: ` `,
        decorationStyle: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        color: ``,
      },
      {
        columns: [
          {
            fontSize: 15,
            bold: false,
            decoration: ``,
            decorationColor: ``,
            text: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            width: 15,
            alignment: `left`,
            pageBreak: ``,
            color: ``,
            preserveLeadingSpaces: true,
          },
          {
            fontSize: 15,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            decorationColor: ``,
            color: ``,
            text: `ข้าพเจ้ารับทราบข้อมูลตามที่ชี้แจง และขอแสดงความยินยอมตามรายละเอียดดังกล่าว โดยหากมีข้อยกเว้นในข้อใด จะระบุให้`,
            decoration: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            width: `auto`,
            bold: false,
            decorationStyle: ``,
          },
        ],
      },
      {
        pageBreak: ``,
        margin: [0, 0, 0, 0],
        decorationStyle: ``,
        color: ``,
        fontSize: 15,
        alignment: `left`,
        preserveLeadingSpaces: true,
        text: `ทราบ ดังนี้`,
        width: `auto`,
        decorationColor: ``,
        bold: false,
        decoration: ``,
      },
      {
        fontSize: 15,
        decorationStyle: ``,
        decoration: ``,
        alignment: `left`,
        pageBreak: ``,
        color: ``,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        text: ` `,
        margin: [0, 0, 0, 0],
        bold: false,
        width: `auto`,
      },
      {
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        bold: false,
        decorationColor: ``,
        margin: [0, 0, 0, 0],
        alignment: `left`,
        text: `.................................................................................................................................................................................................................... `,
        pageBreak: ``,
        fontSize: 15,
        decoration: ``,
        color: ``,
        width: `auto`,
      },
      {
        preserveLeadingSpaces: true,
        decorationColor: ``,
        decorationStyle: ``,
        decoration: ``,
        width: `auto`,
        color: ``,
        text: `.................................................................................................................................................................................................................... `,
        fontSize: 15,
        bold: false,
        pageBreak: ``,
        margin: [0, 0, 0, 0],
        alignment: `left`,
      },
      {
        decorationStyle: ``,
        width: `auto`,
        pageBreak: ``,
        color: ``,
        fontSize: 15,
        decoration: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        text: ` `,
        bold: false,
        margin: [0, 0, 0, 0],
        decorationColor: ``,
      },
      {
        columns: [
          {
            color: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            bold: `true`,
            fontSize: 15,
            alignment: `left`,
            pageBreak: ``,
            decorationColor: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            text: `สำหรับผู้รับบริการ`,
            decoration: ``,
          },
          {
            decoration: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            color: ``,
            decorationColor: ``,
            decorationStyle: ``,
            pageBreak: ``,
            text: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            width: 180,
          },
          {
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            width: `auto`,
            pageBreak: ``,
            alignment: `left`,
            bold: `true`,
            fontSize: 15,
            decoration: ``,
            text: `สำหรับเจ้าหน้าที่`,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            width: 20,
            fontSize: 15,
            decorationStyle: ``,
            text: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            alignment: `left`,
            bold: false,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decoration: ``,
          },
          {
            alignment: `left`,
            bold: false,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            decorationColor: ``,
            color: ``,
            decoration: ``,
            fontSize: 15,
            text: `(ลงชื่อ) ........................................................  `,
            preserveLeadingSpaces: true,
            width: `auto`,
            decorationStyle: ``,
          },
          {
            decorationStyle: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: 90,
            pageBreak: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            color: ``,
            fontSize: 15,
            alignment: `left`,
            text: ``,
          },
          {
            decorationColor: ``,
            text: `(ลงชื่อ) ........................................................  `,
            color: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            width: `auto`,
            alignment: `left`,
            decoration: ``,
            fontSize: 15,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            bold: false,
            color: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            text: ``,
            width: 50,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            pageBreak: ``,
            decoration: ``,
          },
          {
            text: `(.......................................................)`,
            alignment: `left`,
            width: `auto`,
            decorationStyle: ``,
            decorationColor: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            bold: false,
          },
          {
            decorationColor: ``,
            decoration: ``,
            color: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            width: 88,
            text: ``,
            fontSize: 15,
            bold: false,
            alignment: `left`,
          },
          {
            decoration: ``,
            color: ``,
            decorationColor: ``,
            width: `auto`,
            margin: [0, 0, 0, 0],
            text: `เจ้าหน้าที่งานเวชระเบียนและตรวจสอบสิทธิ รหัสบุคคล ........`,
            pageBreak: ``,
            alignment: `left`,
            decorationStyle: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            pageBreak: ``,
            alignment: `left`,
            color: ``,
            decorationColor: ``,
            width: 50,
            fontSize: 15,
            decorationStyle: ``,
            decoration: ``,
            bold: false,
            text: ``,
          },
          {
            alignment: `left`,
            preserveLeadingSpaces: true,
            bold: false,
            text: `วัน เดือน ปี ........ /  ........ /  ........`,
            fontSize: 15,
            width: `auto`,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            decoration: ``,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
          },
          {
            decorationColor: ``,
            bold: false,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            width: 130,
            decorationStyle: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
            text: ``,
          },
          {
            color: ``,
            alignment: `left`,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            width: `auto`,
            bold: false,
            decoration: ``,
            pageBreak: ``,
            text: `วัน เดือน ปี ........ /  ........ /  ........`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            text: ``,
            alignment: `left`,
            color: ``,
            width: 50,
            bold: false,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            pageBreak: ``,
            width: 5,
            fontSize: 15,
            bold: false,
            text: ``,
            decorationColor: ``,
            decoration: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            color: ``,
          },
          {
            color: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            bold: false,
            decorationStyle: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            width: `auto`,
            decorationColor: ``,
            decoration: ``,
            text: `ผู้ป่วย`,
          },
          {
            fontSize: 15,
            decoration: ``,
            pageBreak: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            alignment: `left`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            width: 5,
            color: ``,
            text: ``,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            color: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationColor: ``,
            alignment: `left`,
            decoration: ``,
            bold: false,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            width: 5,
            text: ``,
          },
          {
            color: ``,
            decorationStyle: ``,
            decorationColor: ``,
            alignment: `left`,
            text: `ผู้ปกครอง/ผู้แทนชอบโดยกฎหมาย`,
            fontSize: 15,
            decoration: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            margin: [0, 0, 0, 0],
            decoration: ``,
            decorationColor: ``,
            color: ``,
            alignment: `left`,
            fontSize: 15,
            width: 50,
            bold: false,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            text: ``,
            decorationStyle: ``,
          },
          { image: "unchecked", margin: [0, 4, 0, 0], width: 10 },
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
            bold: false,
            fontSize: 15,
            decorationStyle: ``,
            width: 5,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            text: ``,
            pageBreak: ``,
            color: ``,
          },
          {
            fontSize: 15,
            width: `auto`,
            bold: false,
            pageBreak: ``,
            text: `อื่นๆ เกี่ยวข้องเป็น ............................ ของผู้ป่วย`,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            color: ``,
            decorationStyle: ``,
            alignment: `left`,
            decoration: ``,
          },
        ],
      },
      {
        margin: [0, 0, 0, 0],
        decorationStyle: ``,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        fontSize: 15,
        color: ``,
        bold: false,
        width: `auto`,
        decoration: ``,
        alignment: `left`,
        text: ` `,
      },
      {
        decorationStyle: ``,
        decorationColor: ``,
        width: `auto`,
        color: ``,
        pageBreak: ``,
        text: `การแปลและให้ข้อมูลโดยผู้แปลภาษา`,
        fontSize: 15,
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
        alignment: `left`,
        bold: `true`,
        decoration: ``,
      },
      {
        text: `ข้าพเจ้าได้แปลหนังสือแสดงความยินยอมรับการรักษาทั่วไปแบบผู้ป่วยนอก ให้ผู้ป่วยทราบในภาษา ......................................................`,
        fontSize: 15,
        width: `auto`,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        bold: false,
        margin: [0, 0, 0, 0],
        decoration: ``,
        pageBreak: ``,
        alignment: `left`,
        decorationStyle: ``,
        color: ``,
      },
      {
        decorationColor: ``,
        fontSize: 15,
        margin: [0, 0, 0, 0],
        width: `auto`,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        text: ` `,
        color: ``,
        bold: false,
        decorationStyle: ``,
        alignment: `left`,
        decoration: ``,
      },
      {
        decorationColor: ``,
        margin: [0, 0, 0, 0],
        fontSize: 15,
        text: `สำหรับผู้แปลภาษา (ล่าม)`,
        decorationStyle: ``,
        alignment: `left`,
        width: `auto`,
        bold: `true`,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        decoration: ``,
        color: ``,
      },
      {
        columns: [
          {
            bold: false,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            fontSize: 15,
            alignment: `left`,
            decoration: ``,
            text: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            width: 20,
          },
          {
            text: `(ลงชื่อ) ........................................................  `,
            margin: [0, 0, 0, 0],
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
            bold: false,
            width: `auto`,
            pageBreak: ``,
            decorationStyle: ``,
            alignment: `left`,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            text: ``,
            decoration: ``,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            width: 50,
            decorationColor: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            bold: false,
          },
          {
            width: `auto`,
            fontSize: 15,
            decorationStyle: ``,
            decoration: ``,
            text: `(.......................................................)`,
            color: ``,
            pageBreak: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            bold: false,
            fontSize: 15,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            color: ``,
            pageBreak: ``,
            width: 95,
            text: ``,
          },
          {
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            alignment: `left`,
            width: `auto`,
            text: `ผู้แปลภาษา`,
            pageBreak: ``,
            color: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            fontSize: 15,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            text: ``,
            width: 50,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decoration: ``,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            bold: false,
          },
          {
            alignment: `left`,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            text: `วัน เดือน ปี ........ /  ........ /  ........`,
            pageBreak: ``,
            fontSize: 15,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            width: `auto`,
            bold: false,
            color: ``,
          },
        ],
      },
    ],
    images: injectImages(props.items?.haveHeader),
  };
}
