import base from "../config/base.json";

var _settings: any = null;
const getSettings = () => {
  if (_settings !== null) {
    return _settings
  }
  _settings = new Settings()
  console.log(_settings, "_settings")
  return _settings
}

class Settings {
  API_HOST?: any;
  WS_HOST?: any;
  STATIC_HOST?: any;
  WEB_RTC?: any;
  APP_NAME?: any;
  USERNAME_PASSWORD_LOGIN?: boolean;
  PENTA_ACC_FACEBOOK_LOGIN?: boolean;
  PENTA_ACC_LINE_LOGIN?: boolean;
  PENTA_ACC_EMAIL_LOGIN?: boolean;
  BOOKING_FUNCTION?: boolean;
  BOOKING_VACCINE_FUNCTION?: boolean;
  DASHBOARD?: boolean;
  WEB_PUSH_PUBLIC_KEY?: any;
  LATEST_ENCOUNTER_TOP?: boolean;
  HIDE_QUEUE_FILTER_DATE?: boolean;
  USE_PAYMENT_STATUS_LABEL?: boolean;
  OPEN_FORM_ENCOUNTER?: boolean;
  HIDE_CALL_BUTTON?: boolean;
  COMPANY?: any;
  PROJECT?: any;
  ISHEALTH_PLATFORM_PROFILE?: boolean;
  IGNORE_CAN_CHAT?: boolean;
  DM_BPLUS_CHAT_CHANNELS?: any[];
  MOM_BPLUS_CHAT_CHANNELS?: any[];
  DM_BPLUS_URL?: any;
  MOM_BPLUS_URL?: any;
  LOGIN_WITH_TUH_TRANSFORM?: boolean;
  LOGIN_NEED_HN?: boolean;
  PATIENT_APPOINTMENT?: any;
  ENABLE_ISHEALTH_TELEPHAR?: boolean;
  HOST_EMAIL_LOGIN?: boolean;
  ENABLE_PROFILE_EMAIL?: boolean;
  HIDDEN_MENU?: any[];
  LOGO_REPORT?: any[];

  constructor(domain = "ishealth-mobile-sapiens.web.app") {
    // กันไม่ให้พลาดตรง config.ts
    if (process.env.NODE_ENV === "production") {
        domain = window.location.hostname;
    }
    const favor = this.selectDomain(domain);
    // url of api server
    this.API_HOST = favor.API_HOST || window.location.origin;
    // websocket server host name
    this.WS_HOST = favor.WS_HOST || window.location.hostname;
    // static host for api such as TRIAGE_LEVEL_CLASSIFY
    this.STATIC_HOST = favor.STATIC_HOST || 'https://admin.ishealth.app';
    // host of web rtc server
    this.WEB_RTC = favor.WEB_RTC || 'https://webrtc.drjaysayhi.com';

    this.APP_NAME = favor.APP_NAME || null;

    this.USERNAME_PASSWORD_LOGIN = favor.USERNAME_PASSWORD_LOGIN === undefined ?
      true : favor.USERNAME_PASSWORD_LOGIN;

    // Enable accounts.thevc authentication options
    this.PENTA_ACC_FACEBOOK_LOGIN = favor.PENTA_ACC_FACEBOOK_LOGIN === undefined ?
      true : favor.PENTA_ACC_FACEBOOK_LOGIN;
    this.PENTA_ACC_LINE_LOGIN = favor.PENTA_ACC_LINE_LOGIN === undefined ?
      true : favor.PENTA_ACC_LINE_LOGIN;
    this.PENTA_ACC_EMAIL_LOGIN = favor.PENTA_ACC_EMAIL_LOGIN === undefined ?
      true : favor.PENTA_ACC_EMAIL_LOGIN;

    // Booking function
    this.BOOKING_FUNCTION = favor.BOOKING_FUNCTION === undefined ?
      true : favor.BOOKING_FUNCTION;

    // Booking vaccine function
    this.BOOKING_VACCINE_FUNCTION = favor.BOOKING_VACCINE_FUNCTION === undefined ?
      false : favor.BOOKING_VACCINE_FUNCTION;

    // Dashboard
    this.DASHBOARD = favor.DASHBOARD === undefined ?
      true : favor.DASHBOARD;

    // Webpush
    this.WEB_PUSH_PUBLIC_KEY = favor.WEB_PUSH_PUBLIC_KEY ||
      "BDKyMQyvGoPQ5LvRPBmQGec1_geS441vKvkdYhaNM4WDfd0a403b0BTunKNBST_-mcaR5at3B8FYL-N4xo4x9a0";

    this.LATEST_ENCOUNTER_TOP = favor.LATEST_ENCOUNTER_TOP === undefined ?
      false : favor.LATEST_ENCOUNTER_TOP;

    this.HIDE_QUEUE_FILTER_DATE = favor.HIDE_QUEUE_FILTER_DATE === undefined ? false : favor.HIDE_QUEUE_FILTER_DATE;

    this.USE_PAYMENT_STATUS_LABEL = favor.USE_PAYMENT_STATUS_LABEL === undefined ? false : favor.USE_PAYMENT_STATUS_LABEL;

    this.OPEN_FORM_ENCOUNTER = favor.OPEN_FORM_ENCOUNTER === undefined ? true : favor.OPEN_FORM_ENCOUNTER

    this.HIDE_CALL_BUTTON = favor.HIDE_CALL_BUTTON === undefined ? false : favor.HIDE_CALL_BUTTON

    this.COMPANY = favor.COMPANY === undefined ? "" : favor.COMPANY

    this.PROJECT = favor.PROJECT === undefined ? "" : favor.PROJECT

    this.ISHEALTH_PLATFORM_PROFILE = favor.ISHEALTH_PLATFORM_PROFILE === undefined ? false : favor.ISHEALTH_PLATFORM_PROFILE

    this.IGNORE_CAN_CHAT = favor.IGNORE_CAN_CHAT === undefined ? false : favor.IGNORE_CAN_CHAT

    this.DM_BPLUS_CHAT_CHANNELS = favor.DM_BPLUS_CHAT_CHANNELS === undefined ? [] : favor.DM_BPLUS_CHAT_CHANNELS

    this.MOM_BPLUS_CHAT_CHANNELS = favor.MOM_BPLUS_CHAT_CHANNELS === undefined ? [] : favor.MOM_BPLUS_CHAT_CHANNELS

    this.DM_BPLUS_URL = favor.DM_BPLUS_URL === undefined ? "" : favor.DM_BPLUS_URL

    this.MOM_BPLUS_URL = favor.MOM_BPLUS_URL === undefined ? "" : favor.MOM_BPLUS_URL

    this.LOGIN_WITH_TUH_TRANSFORM = favor.LOGIN_WITH_TUH_TRANSFORM === undefined ? false : favor.LOGIN_WITH_TUH_TRANSFORM
    
    this.LOGIN_NEED_HN = favor.LOGIN_NEED_HN === undefined ? true : favor.LOGIN_NEED_HN

    this.PATIENT_APPOINTMENT = favor.PATIENT_APPOINTMENT === undefined? "" : favor.PATIENT_APPOINTMENT

    this.ENABLE_ISHEALTH_TELEPHAR = favor.ENABLE_ISHEALTH_TELEPHAR === undefined? true : favor.ENABLE_ISHEALTH_TELEPHAR

    this.HOST_EMAIL_LOGIN = favor.HOST_EMAIL_LOGIN === undefined ? "" : favor.HOST_EMAIL_LOGIN

    this.ENABLE_POLICY = favor.ENABLE_POLICY === undefined ? "" : favor.ENABLE_POLICY

    this.ENABLE_PROFILE_EMAIL = favor.ENABLE_PROFILE_EMAIL === undefined? false : favor.ENABLE_PROFILE_EMAIL

    this.HIDDEN_MENU = favor.HIDDEN_MENU === undefined? [] : favor.HIDDEN_MENU
    // console.log(`Settings for ${domain}`);
    // console.log(this);

    this.LOGO_REPORT = favor.LOGO_REPORT === undefined ? [
      { type: 1, width: 600, height: 202, src: "/static/images/logochula_dent.jpg" },
      { type: 2, width: 600, height: 600, src: "/static/images/logochula.png" }
    ] : favor.LOGO_REPORT
  }

  selectDomain(domain) {
    if (domain in base) {
      return base[domain]
    } else if (!'default' in base) {
      throw `cannot find "${domain}" or "default" in settings`
    }
    return base.default
  }
}

// export default new Settings();
export default getSettings();